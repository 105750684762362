import { DealEvent, EEuroDenomination, ProductExport } from './schemaTypes'

export type ValidFromToFieldName =
  | 'validFrom'
  | 'validTo'
  | 'date'
  | 'dateOfBirth'
  | 'checkedAt'
export type ConstructorType<C> = new (...args: any[]) => C

export interface UIError {
  title: string
  description: string | string[] | any
  data?: any
}

export type ProductPriceDiff = Omit<ProductExport, 'price'> & {
  newPrice: number
  oldPrice: number
}

export interface CashBoxAmounts {
  moneyPiecesAmounts: Record<EEuroDenomination, number>
  totalAmount: number
}

export type SelectItem<T extends string = string> = {
  value: T
  label: string
}

export enum MainCategoryType {
  ELECTRONICS = 'Elektronik',
  WATCHES = 'Uhren',
  JEWELLERY = 'Schmuck',
  COINS_AND_BARS = 'Münzen & Barren',
  BAGS = 'Taschen',
  OTHER = 'Sonstiges',
}

export type DealEventType = Exclude<DealEvent['__typename'], undefined>
