import styled from '@emotion/styled'
import {
  Field,
  FieldProps,
  Form,
  FormikErrors,
  FormikProvider,
  useFormik,
} from 'formik'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { FunctionComponent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useUpdatePartialRedemption } from '@/domains/deals/hooks/updatePartialRedemption'
import { PartialRedemptionArgs } from '@/gql/graphql'
import { useMutationShowingErrors } from '@/hooks/useMutationShowingErrors'
import FormikDropdown from '@/redesign/components/FormikFields/FormikDropdown/FormikDropdown'
import InputNum from '@/redesign/components/InputNumber/InputNumber'
import { Deal } from '@/schemaTypes'

export interface Props {
  deal: Deal
}

const DEFAULT_PARTIAL_REDEMPTION_INTERVALS_COUNT = Infinity
const PARTIAL_REDEMPTION_INTERVAL_OPTIONS = [1, 2, 3, 4, 5, Infinity]

const PartialRedemptionDialog: FunctionComponent<Props> = ({ deal }: Props) => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)

  const maxPartialRedemptionAmount = Math.max(
    deal.dealFinalValues.payoutAmount - 50,
    0,
  )

  const updatePartialRedemption = useMutationShowingErrors({
    mutation: useUpdatePartialRedemption(),
    successMessage: `${t('update_successful')}!`,
  })

  const onSubmit = (values: PartialRedemptionArgs) => {
    // Infinity is used in the frontend for the unlimited interval case, because the dropdown component
    // doesnt handle the undefined option properly. For this reason we translate it before the
    // form submission.
    updatePartialRedemption({
      variables: {
        args: {
          ...values,
          partialRedemptionIntervalsCount:
            values.partialRedemptionIntervalsCount === Infinity
              ? undefined
              : values.partialRedemptionIntervalsCount,
        },
      },
    })
    setOpen(false)
  }

  const validate = (values: PartialRedemptionArgs) => {
    const errors: FormikErrors<PartialRedemptionArgs> = {}

    if (values.minPartialRedemptionAmount > maxPartialRedemptionAmount) {
      errors.minPartialRedemptionAmount = t(
        'invalid_min_partial_redemption_amount',
      )
    }

    return errors
  }

  const formik = useFormik({
    initialValues: {
      dealId: deal._id,
      itemId: deal.itemsIds[0],
      minPartialRedemptionAmount:
        deal.dealFinalValues.minPartialRedemptionAmount,
      partialRedemptionIntervalsCount:
        deal.dealFinalValues?.partialRedemptionIntervalsCount ||
        DEFAULT_PARTIAL_REDEMPTION_INTERVALS_COUNT,
    },
    validate,
    onSubmit,
  })

  const { setFieldValue, values, isValid, handleSubmit, handleReset } = formik

  return (
    <>
      <StyledButton
        onClick={() => setOpen(true)}
        type="button"
        severity="secondary"
      >
        {t('partial_redemption_set')}
      </StyledButton>
      <Dialog
        visible={open}
        className="max-w-2xl"
        header={t('partial_redemption_dialog_title')}
        onHide={() => {
          if (!open) return
          setOpen(false)
        }}
        draggable={false}
        resizable={false}
      >
        <FormikProvider value={formik}>
          <Form onSubmit={handleSubmit}>
            <p>{t('partial_redemption_dialog_notice')}</p>

            <Field name="minPartialRedemptionAmount">
              {({ field, meta }: FieldProps<number>) => (
                <InputNum
                  label={t('car_inspection.min_partial_redemption')}
                  className="flex flex-col md:flex-row items-start md:items-center mt-4"
                  labelClassName="text-sm font-semibold w-12.5"
                  inputContainerClassName="w-52"
                  minFractionDigits={2}
                  maxFractionDigits={2}
                  min={0}
                  isInvalid={meta.touched && typeof meta.error === 'string'}
                  errorText={meta.touched && meta.error}
                  {...field}
                  onChange={(event) => {
                    setFieldValue('minPartialRedemptionAmount', event.value)
                    if (!event.value) {
                      setFieldValue('partialRedemptionIntervalsCount', null)
                    }
                    if (
                      event.value &&
                      !values.partialRedemptionIntervalsCount
                    ) {
                      setFieldValue(
                        'partialRedemptionIntervalsCount',
                        DEFAULT_PARTIAL_REDEMPTION_INTERVALS_COUNT,
                      )
                    }
                  }}
                />
              )}
            </Field>

            <FormikDropdown
              name="partialRedemptionIntervalsCount"
              label={t('partial_redemption_intervals_label')}
              options={PARTIAL_REDEMPTION_INTERVAL_OPTIONS.map((value) => ({
                value,
                label:
                  value === Infinity
                    ? t(
                        `car_inspection.partial_redemption_interval.amount_unlimited`,
                      )
                    : t(`car_inspection.partial_redemption_interval.amount`, {
                        count: value,
                      }),
              }))}
              className="flex flex-col md:flex-row items-start md:items-center mt-2"
              labelClassName="text-sm font-semibold w-12.5"
              inputContainerClassName="w-52"
              disabled={!values.minPartialRedemptionAmount}
            />

            <div className="flex justify-end gap-2 mt-7">
              <Button
                icon="pi pi-times"
                label={t('cancel')}
                type="reset"
                onClick={(event) => {
                  setOpen(false)
                  handleReset(event)
                }}
                text
              />
              <Button
                icon="pi pi-check"
                label={t('continue')}
                disabled={!isValid}
                type="submit"
              />
            </div>
          </Form>
        </FormikProvider>
      </Dialog>
    </>
  )
}

export default PartialRedemptionDialog

const StyledButton = styled(Button)`
  color: var(--button-secondary-color, #fff);
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.05rem;
`
