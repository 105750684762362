import {
  includeHidedCategories,
  useGetItemCategories,
} from '../../../../itemCategories/hooks'
import { CustomDealItem } from '../../../helpers'
import { CustomDealOriginalValue } from '../../CustomDealOriginalValue'
import { PriceDetermination } from '../PriceDetermination'
import styled from '@emotion/styled'
import {
  Button,
  DialogActions,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core'
import { map } from 'lodash'
import { useTranslation } from 'react-i18next'
import { ItemFormAnswers } from '@/components/ItemFormAnswers'
import LabelValueTable, {
  LabelValueTableRowProps,
} from '@/components/LabelValueTable'
import { useFormatVariantProperties } from '@/components/ProductSelector/helper'
import ItemCategoryQuestions from '@/domains/items/components/Item/ItemEvaluation/ItemCategoryQuestions'
import Input from '@/redesign/components/Input/Input'
import { EDealType, ProductVariant } from '@/schemaTypes'
import { MainCategoryType } from '@/types'
import { isVehicle } from '@/utils/misc'
import styles from './styles.module.scss'

interface Props {
  item: CustomDealItem
  dealType: EDealType
  productVariant?: ProductVariant
  onEditItem: () => void
}

export function CustomDealItemDetail(props: Props) {
  const { t } = useTranslation()
  const { item, dealType, onEditItem, productVariant } = props
  const {
    title,
    note,
    policeNumber,
    vehicleData,
    itemCategoryId,
    answers,
    priceFindings,
    formAnswers,
    foundComparableOffers,
  } = item
  const { itemCategories } = useGetItemCategories(includeHidedCategories)
  const currentCategory = itemCategories.find(
    (category) => category._id === itemCategoryId,
  )
  const isJewellery = itemCategories.some(
    (category) => category.name === MainCategoryType.JEWELLERY,
  )

  const vehicleProperties = vehicleData?.vehicleProperties

  const vehiclePropertiesRows = map(
    vehicleProperties ?? {},
    (vehiclePropertyValue, vehiclePropertyKey) => ({
      label: t(`car_${vehiclePropertyKey}`),
      value:
        typeof vehiclePropertyValue !== 'object' &&
        vehiclePropertyKey !== '__typename'
          ? vehiclePropertyValue
          : '',
    }),
  ).filter((item) => Boolean(item.value))

  const images = item.media

  const formattedVariantProperties = useFormatVariantProperties(productVariant)

  const itemSpecificationRows: LabelValueTableRowProps[] = [
    ...(note
      ? [
          {
            label: t('note'),
            value: note ?? '',
            renderValue: (value) => (
              <div className="u-flex">
                <TextField
                  className={styles.flexEqualSizeChild}
                  disabled
                  multiline
                  value={value}
                />
              </div>
            ),
          },
        ]
      : []),
    ...(policeNumber
      ? [
          {
            label: t('police_number'),
            value: policeNumber ?? '',
            renderValue: () => (
              <div className="u-flex">
                <TextField
                  className={styles.flexEqualSizeChild}
                  type={'string'}
                  disabled
                  value={policeNumber ?? null}
                />
                <div className={`u-mt-8 ${styles.flexEqualSizeChild}`}>
                  <CustomDealOriginalValue
                    value={policeNumber?.toString() ?? ''}
                  />
                </div>
              </div>
            ),
          },
        ]
      : []),
    ...vehiclePropertiesRows,
    ...(formAnswers?.length
      ? [
          {
            label: t('addition_questions'),
            value: null,
            renderValue: () => (
              <ItemFormAnswers formAnswers={formAnswers} isEditable={false} />
            ),
          },
        ]
      : []),
  ]

  const itemFeeRows: LabelValueTableRowProps[] = [
    {
      label: t('item_questions'),
      value: '',
      renderValue: () => (
        <div>
          {answers && (
            <ItemCategoryQuestions
              isReadOnly={true}
              itemAnswerArgs={answers}
              itemCategoryId={itemCategoryId}
              setItemAnswerArgs={() => {}}
              hideConditionQuestion={!!formAnswers?.length}
              omitDeactivatedQuestionsForNewProducts={Boolean(item.variantId)}
            />
          )}
        </div>
      ),
    },
  ]

  const payoutAmount =
    dealType === EDealType.Pawn
      ? item.pawnPayoutAmount
      : item.purchasePayoutAmount

  return (
    <>
      <DialogContent>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell style={{ paddingLeft: 0, width: 210 }}>
                <Typography variant="h6">{t('product_name')}</Typography>
              </TableCell>
              <TableCell>{title}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t('category')}</TableCell>
              <TableCell>{currentCategory?.name}</TableCell>
            </TableRow>
          </TableBody>
        </Table>

        <ItemDetail>
          <div>
            <Typography variant="h6" gutterBottom>
              {t('item_specification')}
            </Typography>
            <StyledLabelValueTable
              rows={itemSpecificationRows}
              displayHeader={false}
              renderLabelColumn={(label) => (
                <span className="o-type-semibold">{label}</span>
              )}
            />
            {formattedVariantProperties &&
              !isJewellery &&
              formattedVariantProperties.map((property) => {
                return (
                  <Input
                    value={property.value}
                    label={property.title}
                    disabled={true}
                    className="flex flex-col md:flex-row items-start md:items-center mb-2"
                    labelClassName="text-sm font-semibold w-12.5"
                    inputContainerClassName="w-52"
                  />
                )
              })}
          </div>

          <div>
            <Typography variant="h6" gutterBottom>
              {t('additional_fee')}
            </Typography>

            <StyledLabelValueTable
              rows={itemFeeRows}
              displayHeader={false}
              renderLabelColumn={(label) => (
                <span className="o-type-semibold">{label}</span>
              )}
            />
          </div>
        </ItemDetail>

        <ItemPricing>
          {!!priceFindings?.filter((c) => c.price)?.length && (
            <PriceDetermination
              isVehicle={isVehicle(item?.itemCategoryId)}
              itemPawnValuesEntry={item.itemPawnValueEntry}
              itemPurchaseValuesEntry={item.itemPurchaseValueEntry}
              dealType={dealType}
              payoutAmount={payoutAmount}
              foundComparableOffers={foundComparableOffers}
              values={priceFindings}
              readonly
              setFieldValue={undefined}
              calculationRunning={false}
              foundOffersEvaluationFactor={item.foundOffersEvaluationFactor}
              overwrittenItemValue={item.overwrittenItemValue}
              counterofferPayoutAmount={item.counterofferPayoutAmount}
            />
          )}
        </ItemPricing>

        <div className="u-mb-20 u-mt-20">
          <Typography variant="h6" gutterBottom>
            {t('product_pictures')}
          </Typography>

          {(images ?? []).map((media, index) => (
            <a
              key={index}
              href={media?.url ?? ''}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                style={{ maxWidth: 300, maxHeight: 300 }}
                key={index}
                alt="custom deal media"
                src={media?.url ?? ''}
              />
            </a>
          ))}
        </div>
      </DialogContent>

      <DialogActions>
        <Button color="primary" onClick={onEditItem}>
          {t('edit')}
        </Button>
      </DialogActions>
    </>
  )
}

const ItemDetail = styled.div`
  margin-top: 2rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 2rem;
`

const StyledLabelValueTable = styled(LabelValueTable)`
  & > tbody > tr > td:first-of-type {
    display: none;
  }

  & > tbody > tr > td:last-of-type {
    border: none;
  }
`

const ItemPricing = styled.div`
  margin-top: 2rem;
`
