import { itemFragments } from '../itemDetails/itemFragments'
import { QueryHookOptions, useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import { useContext } from 'react'
import { Context } from '@/context'
import { noteFragments } from '@/domains/deals/hooks/noteFragments'
import { useShowErrorsPopup } from '@/hooks'
import { fragments } from '@/hooks/fragments'
import { ELanguageCode, Query, QueryGetItemByIdArgs } from '@/schemaTypes'

const GET_ITEM_BY_ID = gql`
  query getItemById($itemId: ObjectId!, $languageCode: ELanguageCode!) {
    getItemById(itemId: $itemId) {
      _id
      productVariant {
        id
        name
        product {
          name
          category {
            parentCategories {
              name
            }
          }
          availableProperties {
            ... on ProductAvailableTextProperty {
              propertyDefinitionId
              propertyDefinition {
                _id
                type
                impactsLook
                sortPriority
                description {
                  allTranslations {
                    text
                    languageCode
                  }
                }
                name {
                  allTranslations {
                    text
                    languageCode
                  }
                }
                key
                valueFormatting {
                  suffix
                }
              }
              values {
                id
                translation {
                  allTranslations {
                    text
                    languageCode
                  }
                }
              }
            }

            ... on ProductAvailableNumberProperty {
              propertyDefinitionId
              propertyDefinition {
                _id
                type
                impactsLook
                sortPriority
                description {
                  allTranslations {
                    text
                    languageCode
                  }
                }
                name {
                  allTranslations {
                    text
                    languageCode
                  }
                }
                key
                valueFormatting {
                  suffix
                }
              }
              values {
                numberValue
              }
            }

            ... on ProductAvailableBooleanProperty {
              propertyDefinitionId
              propertyDefinition {
                _id
                type
                impactsLook
                sortPriority
                description {
                  allTranslations {
                    text
                    languageCode
                  }
                }
                name {
                  allTranslations {
                    text
                    languageCode
                  }
                }
                key
                valueFormatting {
                  suffix
                }
              }
            }
          }
          variants {
            id
            propertiesValues {
              ...ProductVariantValueFragment
            }
          }
        }
        propertiesValues {
          ...ProductVariantValueFragment
        }
      }
      serialNumber
      imei
      itemSpecification
      note
      policeNumber
      isDamageCase
      isForPersonalUse
      isContinueUsing
      customRequestId
      customDealId
      companyId
      usedGrade
      itemInAuctionShop
      company {
        successionCompanyId
        iban
        bic
        accountOwner
        headquarter {
          countryCode
        }
      }
      damageCasePeriods {
        from
        to
      }
      personalUsePeriods {
        from
        to
      }
      isInvestmentGold
      createdAt
      updatedAt
      ean
      storageFacilityId
      isValuable
      storageFacility {
        _id
        storageUnitIds
      }
      media {
        ...FileMetadataFragment
      }
      vehicleData {
        vehicleProperties {
          make
          model
          regdate
          body
          facelift
          seats
          engine
          transmission
          odometer
          otherVehicleProperties {
            value
            name
          }
        }
        indicataId
        carPriceLastFetched
        vinLastFetched
        vin
        hasTypeCertificate
        hasApprovalCertificate
        has57aGutachten
        has57aGutachtenDue
        hasSecondKey
        hasSaleContract
        licensePlate
        spareTireCount
        ksvQueryHistories {
          type
          queryAt
          vin
          meta
        }
      }
      dealsHistory {
        dealId
        algoliaReference
        variantId
        unconfirmedPropertyIds
        title
        material
        answers {
          questionId
          selectedOptionIndex
          rangeValue
        }
        questions {
          ...ItemQuestionFragment
        }
        questionIds
        itemCategoryId
        values {
          verified {
            currentMarketValue
            predictedMarketValue
            adjustedMarketValue
            loanToValue
            maxMarketValue
            payoutAmount
            date
          }
        }
      }
      isInStore
      itemNotifications
      lastBookingNumber
      bookingNumbers
      piceaUploadLink {
        ...FileMetadataFragment
      }

      entrupyCertificateUrl
      checkedBy
      checkedAt
      uploadedFiles {
        ...FileMetadataFragment
      }
      storageHistory {
        storageUnits {
          lvl1
          lvl2
          lvl3
          lvl4
        }
        createdAt
        timestamp
        storageStatus
        employeeId
        note
      }
      storageLabel
      events {
        ...ItemEventFragment
      }
      internalSaleData {
        date
        price
        purchasedDirectly
      }
      formAnswers {
        ...FormAnswerFragment
      }
      checkedAfterAuction
      piceaTest {
        disable
        error
        data {
          label
          value
        }
        createdAt
      }
      manualTest {
        createdAt
        createdBy {
          firstname
          lastname
        }
        assessments {
          assessment
          passed
        }
      }
      itemValorizationCalculation {
        ...ItemValorizationCalculationFragment
      }
      notes {
        ...NoteFragment
      }
      categorizedUploadedFiles {
        fileCategoryName
        uploadedFiles {
          ...FileMetadataFragment
        }
      }
    }
  }
  ${fragments.fileMetadata}
  ${fragments.customDealFormQuestion}
  ${itemFragments.itemQuestionFragment}
  ${itemFragments.itemEvent}
  ${itemFragments.itemValorizationCalculation}
  ${noteFragments.notes}
  ${fragments.customDealFormQuestion}
  ${fragments.formAnswers}
  ${fragments.productVariantValue}
`

type TData = { getItemById: Query['getItemById'] }
type TVariables = { languageCode?: ELanguageCode } & QueryGetItemByIdArgs

export function useGetItemById(opts: QueryHookOptions<TData, TVariables> = {}) {
  const { language } = useContext(Context)

  const queryResult = useQuery<TData, TVariables>(GET_ITEM_BY_ID, {
    fetchPolicy: 'network-only',
    ...opts,
    variables: { languageCode: language, ...(opts.variables as TVariables) },
  })

  useShowErrorsPopup(queryResult.error)

  return { queryResult, item: queryResult.data?.getItemById }
}
