import { CarExtensionDateAndInspection } from '../CarExtensionSettings/CarExtensionDateAndInspection'
import { CarExtensionWarning } from '../CarExtensionSettings/CarExtensionWarning'
import { MinPartialRedemptionDisplay } from '../CarExtensionSettings/MinPartialRedemptionDisplay'
import PaymentDetails from '../PaymentDetails'
import StatusDropdown from '../StatusDropdown/StatusDropdown'
import UploadsOverview from '../UploadDealOverview/UploadDealOverview'
import VerificationChecklist from '../VerificationChecklist/VerificationChecklist'
import styled from '@emotion/styled'
import classNames from 'classnames'
import dayjs from 'dayjs'
import { Button } from 'primereact/button'
import { Message } from 'primereact/message'
import { useContext, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import LoadingSpinner from '@/components/LoadingSpinner'
import { Context } from '@/context'
import { useGetOpenAuction } from '@/domains/auctions/hooks/auctionList/getOpenAuction'
import { useGetCustomDealForDealDetail } from '@/domains/deals/hooks'
import { useUpdateCustomerCategory } from '@/domains/deals/hooks/updateCustomerCategory'
import { useMutationShowingErrors } from '@/hooks'
import { useGetCustomRequest } from '@/pages/InApp/CustomRequests/hooks/getCustomRequest'
import DownloadButton from '@/redesign/components/DownloadButton/DownloadButton'
import Input from '@/redesign/components/Input/Input'
import InputDropdown from '@/redesign/components/InputDropdown/InputDropdown'
import SectionTitle from '@/redesign/components/SectionTitle/SectionTitle'
import Switch from '@/redesign/components/Switch/Switch'
import {
  Deal,
  DealClosedEvent,
  DealEvent,
  DealExtensionConfirmedEvent,
  DealPaybackConfirmedEvent,
  DealReviewingEvent,
  DealSoldInternEvent,
  DealValuesEntry,
  DealVerifiedEvent,
  EBusinessUnit,
  ECustomerCategory,
  EDealPartnerType,
  EDealStatusType,
  EDealType,
} from '@/schemaTypes'
import { DealEventType } from '@/types'
import { getLatestEventForStatus } from '@/utils/deal'
import { printLocalAmount } from '@/utils/misc'
import { KSVQueryHistory } from './KSVQueryHistory'
import OnlineExtensionNoteDialog from './OnlineExtensionNoteDialog'
import PartialRedemptionDialog from './PartialRedemptionDialog'

export interface DealGeneralInfoDialog {
  extensionSwitchDialog: boolean
}

export interface LatestDealEvents {
  verifiedEvent: DealVerifiedEvent
  closedEvent: DealClosedEvent
  soldInternEvent: DealSoldInternEvent
  paybackConfirmedEvent: DealPaybackConfirmedEvent
  extensionConfirmedEvent: DealExtensionConfirmedEvent
  reviewingEvent: DealReviewingEvent
}

export interface GeneralInfoProps {
  deal: Deal
  latestDealValues?: DealValuesEntry
  activeEvent?: DealEvent
  warnBookedDate: boolean
  dealStatusLoading?: boolean
  dealVerificationError?: boolean
  activePaybackOrExtensionRequestedEvent?: DealEvent
  onDealStatusChange: (status: DealEventType) => void
  onCreateReceiptWithoutIdentity: () => void
  onUpdateIsOnlineExtensionPrevented: (flag: boolean, note?: string) => void
  receiptGeneratingIsRunning: boolean
  queryResult
}

export function GeneralInfo(props: GeneralInfoProps) {
  const {
    deal,
    latestDealValues,
    activeEvent,
    onDealStatusChange,
    warnBookedDate,
    dealStatusLoading,
    onCreateReceiptWithoutIdentity,
    onUpdateIsOnlineExtensionPrevented,
    receiptGeneratingIsRunning,
  } = props
  const { isGuestUser } = useContext(Context)
  const { t } = useTranslation()

  const [open, setOpen] = useState<DealGeneralInfoDialog>({
    extensionSwitchDialog: false,
  })

  const [customerCategory, setCustomerCategory] = useState<
    ECustomerCategory | undefined
  >(deal.customerCategory)

  const customerCategoryDiffers = customerCategory !== deal.customerCategory

  const event: LatestDealEvents = useMemo(() => {
    const verifiedEvent = getLatestEventForStatus(deal, 'DealVerifiedEvent')
    const closedEvent = getLatestEventForStatus(deal, 'DealClosedEvent')
    const soldInternEvent = getLatestEventForStatus(deal, 'DealSoldInternEvent')
    const paybackConfirmedEvent = getLatestEventForStatus(
      deal,
      'DealPaybackConfirmedEvent',
    )
    const extensionConfirmedEvent = getLatestEventForStatus(
      deal,
      'DealExtensionConfirmedEvent',
    )
    const reviewingEvent = getLatestEventForStatus(deal, 'DealReviewingEvent')

    return {
      verifiedEvent,
      closedEvent,
      soldInternEvent,
      paybackConfirmedEvent,
      extensionConfirmedEvent,
      reviewingEvent,
    }
  }, [deal])

  const issuanceCertificateUrl =
    activeEvent?.__typename === 'DealReviewingEvent'
      ? event.reviewingEvent?.issuanceCertificate?.url
      : undefined

  const items = useMemo(() => deal.items, [deal.items])
  const customRequestIds = items.map((item) => item.customRequestId)

  const { customRequest } = useGetCustomRequest({
    variables: {
      customRequestId: customRequestIds[0] ?? '',
    },
    skip: !customRequestIds || customRequestIds.length === 0,
  })

  const { customDeal } = useGetCustomDealForDealDetail({
    variables: {
      customDealId: deal.customDealId,
    },
    skip: !deal.customDealId,
  })

  const { openAuctionData } = useGetOpenAuction({
    variables: {
      dealId: deal._id,
    },
  })

  const payoutAmount = deal.dealFinalValues.payoutAmount
  const durationInDays = latestDealValues?.durationInDays ?? 0

  let closedDueTo: string | undefined

  if (deal.dealType === EDealType.Purchase && event.closedEvent) {
    closedDueTo = t('purchase')
  } else if (event.extensionConfirmedEvent) {
    closedDueTo = t('extension')
  } else if (event.paybackConfirmedEvent) {
    closedDueTo = t('payback')
  } else if (
    event.soldInternEvent ||
    (deal.dealType === EDealType.Pawn && event.closedEvent?.isClosedByAuctionId)
  ) {
    // keep the soldInternEvent for backward compatibility with already sold intern deals
    closedDueTo = t('auction.auction_status')
  } else if (event.closedEvent) {
    closedDueTo = t('payback') // some old pawns do not have PAYBACK_CONFIRMED
  }

  const knpDebtInfo =
    deal.dealPartnerData?.type === EDealPartnerType.Knp &&
    deal.dealPartnerData.knp &&
    payoutAmount
      ? getKnpDebtInfo({
          debtAmount: deal.dealPartnerData.knp.debtAmount,
          payoutAmount,
        })
      : undefined

  const customerCategoriesOptions = useMemo(() => {
    return Object.entries(ECustomerCategory).map(([label, value]) => ({
      label,
      value,
    }))
  }, [])

  const updateCustomerCategory = useMutationShowingErrors({
    mutation: useUpdateCustomerCategory(),
    successMessage: `${t('update_successful')}!`,
  })

  const onSaveCustomerCategory = () => {
    updateCustomerCategory({
      variables: {
        customerCategoryArgs: { dealId: deal._id, customerCategory },
      },
    })
  }

  return (
    <div>
      <div className="mb-8">
        <Input
          value={`${deal.bookingNumber}`}
          label={t('deal.booking_number')}
          disabled={true}
          className="flex flex-col md:flex-row items-start md:items-center mb-2"
          labelClassName="text-sm font-semibold w-12.5"
          inputContainerClassName="w-52"
        />
        {deal.businessUnit === EBusinessUnit.Car && (
          <>
            {/* This should be replaced to Item overview page */}
            <Input
              value={items[0].isContinueUsing ? t('yes') : t('no')}
              label={t('deal.pawn.continue_using')}
              disabled={true}
              className="flex flex-col md:flex-row items-start md:items-center mb-2"
              labelClassName="text-sm font-semibold w-12.5"
              inputContainerClassName="w-52"
            />
          </>
        )}
        <Input
          value={deal.businessUnit}
          label={t('deal.business_unit')}
          disabled={true}
          className="flex flex-col md:flex-row items-start md:items-center mb-2"
          labelClassName="text-sm font-semibold w-12.5"
          inputContainerClassName="w-52"
        />
        {deal.dealType === EDealType.Pawn && (
          <>
            {event.verifiedEvent && (
              <div className="flex flex-row">
                <Input
                  value={`${deal.pawnData?.pawnNumber}`}
                  label={t('deal.pawn.number')}
                  disabled={true}
                  className="flex flex-col md:flex-row items-start md:items-center mb-2"
                  labelClassName="text-sm font-semibold w-12.5"
                  inputContainerClassName="w-52"
                />
                {deal.pawnData?.pawnTicketLink &&
                  deal.pawnData.pawnTicketLink.url && (
                    <DownloadButton
                      className={'ml-4'}
                      label={t('pawn_ticket')}
                      link={deal.pawnData.pawnTicketLink.url}
                    />
                  )}
              </div>
            )}
            {deal.pawnData?.billNumber && (
              <div className="flex flex-row">
                <Input
                  value={`${deal.pawnData?.billNumber}`}
                  label={t('deal.pawn.bill_number')}
                  disabled={true}
                  className="flex flex-col md:flex-row items-start md:items-center mb-2"
                  labelClassName="text-sm font-semibold w-12.5"
                  inputContainerClassName="w-52"
                />
                {deal.pawnData?.billLink && deal.pawnData.billLink.url && (
                  <DownloadButton
                    className={'ml-4'}
                    label={t('bill')}
                    link={deal.pawnData.billLink.url}
                  />
                )}
              </div>
            )}
          </>
        )}
        {deal.dealType === EDealType.Purchase && (
          <>
            {event.closedEvent && (
              <div className="flex flex-row">
                <Input
                  value={`${deal.purchaseData?.receiptNumber}`}
                  label={t('receipt_number')}
                  disabled={true}
                  className="flex flex-col md:flex-row items-start md:items-center mb-2"
                  labelClassName="text-sm font-semibold w-12.5"
                  inputContainerClassName="w-52"
                />

                {deal.purchaseData?.receiptLink &&
                  deal.purchaseData.receiptLink.url && (
                    <DownloadButton
                      className={'ml-4'}
                      label={t('receipt')}
                      link={deal.purchaseData.receiptLink.url}
                    />
                  )}
                {deal.purchaseData?.receiptLinkWithoutIdentity ? (
                  <DownloadButton
                    className={'ml-4'}
                    label={t('download_receipt_without_identity')}
                    link={deal.purchaseData.receiptLinkWithoutIdentity.url}
                  />
                ) : (
                  <StyledButton
                    className={'!ml-4'}
                    severity="secondary"
                    onClick={onCreateReceiptWithoutIdentity}
                  >
                    {t('generate_receipt_without_identity')}
                  </StyledButton>
                )}
                {receiptGeneratingIsRunning && (
                  <div className={'ml-1.5'}>
                    <LoadingSpinner />
                  </div>
                )}
                <br />
              </div>
            )}
          </>
        )}
        <Input
          value={deal.company.name}
          label={t('company.singular')}
          disabled={true}
          className="flex flex-col md:flex-row items-start md:items-center mb-2"
          labelClassName="text-sm font-semibold w-12.5"
          inputContainerClassName="w-52"
        />
        <Input
          value={deal.shop?.name || '-'}
          label={t('shop.label')}
          disabled={true}
          className="flex flex-col md:flex-row items-start md:items-center mb-2"
          labelClassName="text-sm font-semibold w-12.5"
          inputContainerClassName="w-52"
        />
        <Input
          value={
            deal.dealType === EDealType.Purchase
              ? t('purchase')
              : `${durationInDays} Tage`
          }
          label={t('duration')}
          disabled={true}
          className="flex flex-col md:flex-row items-start md:items-center mb-2"
          labelClassName="text-sm font-semibold w-12.5"
          inputContainerClassName="w-52"
        />

        {event.verifiedEvent && (
          <>
            <SectionTitle title={t('deal_extension_settings_headline')} />
            {deal.businessUnit === EBusinessUnit.Car &&
              deal.items[0].isContinueUsing && (
                <CarExtensionDateAndInspection
                  className="mb-2"
                  deal={deal}
                  event={event}
                />
              )}

            {deal.dealType === EDealType.Pawn && (
              <div className="flex flex-row items-center mb-2 gap-2">
                <Switch
                  disabled={
                    Boolean(event.closedEvent) ||
                    Boolean(event.paybackConfirmedEvent) ||
                    !event.verifiedEvent ||
                    (deal.dealFinalValues.isOnlineExtensionPrevented &&
                      deal.items[0].isContinueUsing)
                  }
                  label={t('allow_online_extension')}
                  checked={!deal.dealFinalValues.isOnlineExtensionPrevented}
                  onChange={(event) => {
                    if (!event.value) {
                      setOpen({
                        ...open,
                        extensionSwitchDialog: true,
                      })
                    } else {
                      onUpdateIsOnlineExtensionPrevented(!event.value)
                    }
                  }}
                  className="flex flex-col md:flex-row items-start md:items-center"
                  labelClassName="text-sm font-semibold w-12.5"
                  wrapperInputContainerClassName="w-[214px]"
                  tooltipText={t('allow_online_extension_description')}
                  tooltipIcon={'pi pi-question-circle'}
                />
                {[
                  EDealStatusType.Verified,
                  EDealStatusType.PayedAndStored,
                  EDealStatusType.LoanDue,
                  EDealStatusType.LoanDueNotified,
                  EDealStatusType.OnSell,
                ].includes(deal.status) && (
                  <PartialRedemptionDialog deal={deal} />
                )}

                {deal.businessUnit === EBusinessUnit.Car &&
                deal.items[0].isContinueUsing ? (
                  <CarExtensionWarning deal={deal} />
                ) : (
                  deal.dealFinalValues.isOnlineExtensionPrevented && (
                    <Message
                      severity="warn"
                      text={t('allow_online_extension_warning')}
                    />
                  )
                )}
              </div>
            )}

            {deal.dealFinalValues.minPartialRedemptionAmount !== undefined && (
              <MinPartialRedemptionDisplay deal={deal} />
            )}
          </>
        )}

        {deal.businessUnit === EBusinessUnit.Car && (
          <div className="flex justify-start">
            <InputDropdown
              value={customerCategory}
              disabled={!!event.verifiedEvent}
              label={t('customer_category')}
              options={customerCategoriesOptions}
              className="flex flex-col md:flex-row items-start md:items-center"
              labelClassName="text-sm font-semibold w-12.5"
              inputContainerClassName="w-52"
              onChange={(e) => setCustomerCategory(e.target.value)}
            />

            <div>
              <Button
                icon="pi pi-replay"
                severity="secondary"
                className={classNames('!mr-0.5', {
                  '!hidden': !customerCategoryDiffers,
                })}
                onClick={() => setCustomerCategory(deal.customerCategory)}
                text
              />
              <Button
                icon="pi pi-save"
                severity="secondary"
                className={classNames({
                  '!hidden': !(customerCategoryDiffers && !!customerCategory),
                })}
                onClick={() => onSaveCustomerCategory()}
                text
              />
            </div>
          </div>
        )}

        {openAuctionData && openAuctionData.isDealExistsInAnOpenAuctions && (
          <Input
            value={
              openAuctionData
                ? t('yes') +
                  ' (' +
                  dayjs(openAuctionData.auctionDate).format('DD.MM.YYYY') +
                  ' )'
                : ''
            }
            label={t('in_active_auction')}
            disabled={true}
            className="flex flex-col md:flex-row items-start md:items-center mb-mb-2"
            labelClassName="text-sm font-semibold w-12.5"
            inputContainerClassName="w-52"
          />
        )}
        {knpDebtInfo && payoutAmount && (
          <div className="flex flex-col">
            <Input
              value={`${printLocalAmount(knpDebtInfo.debtAmount)}`}
              label={t('kpn_old_debt')}
              disabled={true}
              className="flex flex-col md:flex-row items-start md:items-center mb-2"
              labelClassName="text-sm font-semibold w-12.5"
              inputContainerClassName="w-52"
            />
            <Input
              value={`${printLocalAmount(knpDebtInfo.remainingDebt)}`}
              label={t('knp_new_debt')}
              disabled={true}
              className="flex flex-col md:flex-row items-start md:items-center mb-2"
              labelClassName="text-sm font-semibold w-12.5"
              inputContainerClassName="w-52"
            />
            <Input
              value={`${printLocalAmount(knpDebtInfo.debtPayout)}`}
              label={t('knp_payout_amount')}
              disabled={true}
              className="flex flex-col md:flex-row items-start md:items-center mb-2"
              labelClassName="text-sm font-semibold w-12.5"
              inputContainerClassName="w-52"
            />
            <Input
              value={`${printLocalAmount(knpDebtInfo.cashyPayout)}`}
              label={t('cashy_payout_amount')}
              disabled={true}
              className="flex flex-col md:flex-row items-start md:items-center mb-2"
              labelClassName="text-sm font-semibold w-12.5"
              inputContainerClassName="w-52"
            />
          </div>
        )}
      </div>
      <div className="mb-8">
        <PaymentDetails deal={deal} />
      </div>
      <div className="mb-8">
        <VerificationChecklist deal={deal} />
      </div>
      <div className="mb-8">
        <SectionTitle title={t('status_information')} />
        <KSVQueryHistory deal={deal} />
        {!!closedDueTo && (
          <Input
            value={closedDueTo}
            label={t('closed_due_to')}
            disabled={true}
            className="flex flex-col md:flex-row items-start md:items-center mb-2"
            labelClassName="text-sm font-semibold w-12.5"
            inputContainerClassName="w-52"
          />
        )}
        {deal.verifiedBy && (
          <Input
            value={deal.verifiedBy ?? ''}
            label={t('verified_by')}
            disabled={true}
            className="flex flex-col md:flex-row items-start md:items-center mb-2"
            labelClassName="text-sm font-semibold w-12.5"
            inputContainerClassName="w-52"
          />
        )}
        {customRequest && customRequest.acceptedBy && (
          <Input
            value={customRequest.acceptedBy ?? ''}
            label={t('accepted_by')}
            disabled={true}
            className="flex flex-col md:flex-row items-start md:items-center mb-2"
            labelClassName="text-sm font-semibold w-12.5"
            inputContainerClassName="w-52"
          />
        )}
        {customDeal?.acceptedBy && (
          <Input
            value={`${customDeal.acceptedBy.firstname} ${customDeal.acceptedBy.lastname}`}
            label={t('accepted_by')}
            disabled={true}
            className="flex flex-col md:flex-row items-start md:items-center mb-2"
            labelClassName="text-sm font-semibold w-12.5"
            inputContainerClassName="w-52"
          />
        )}
        {deal.extendedBy && (
          <Input
            value={deal.extendedBy ?? ''}
            label={t('extended_by')}
            disabled={true}
            className="flex flex-col md:flex-row items-start md:items-center mb-2"
            labelClassName="text-sm font-semibold w-12.5"
            inputContainerClassName="w-52"
          />
        )}
        {deal.closedBy && (
          <Input
            value={deal.closedBy}
            label={t('closed_by')}
            disabled={true}
            className="flex flex-col md:flex-row items-start md:items-center mb-2"
            labelClassName="text-sm font-semibold w-12.5"
            inputContainerClassName="w-52"
          />
        )}
        <div className="flex flex-wrap items-center">
          <div>
            {activeEvent && activeEvent.__typename && (
              <StatusDropdown
                disabled={isGuestUser()}
                deal={deal}
                loading={dealStatusLoading}
                selected={activeEvent.__typename}
                onEventChange={onDealStatusChange}
                warnBookedDate={warnBookedDate}
              />
            )}
          </div>
        </div>
        {issuanceCertificateUrl && (
          <a
            className="underline underline-offset-2"
            href={issuanceCertificateUrl}
            target="_blank"
            rel="noreferrer"
          >
            {t('download_issuance_certificate')}
          </a>
        )}
      </div>

      <UploadsOverview deal={deal} />

      <div>
        <OnlineExtensionNoteDialog
          open={open.extensionSwitchDialog}
          setOpen={(value) =>
            setOpen({
              ...open,
              extensionSwitchDialog: value,
            })
          }
          onUpdateIsOnlineExtensionPrevented={
            onUpdateIsOnlineExtensionPrevented
          }
        />
      </div>
    </div>
  )
}

export function getKnpDebtInfo(opts: {
  payoutAmount: number
  debtAmount: number
}) {
  let cashyPayout = 0
  let debtPayout = 0

  const debtAmount = opts.debtAmount
  if (debtAmount > opts.payoutAmount) {
    debtPayout = opts.payoutAmount
    cashyPayout = 0
  } else {
    debtPayout = debtAmount
    cashyPayout = opts.payoutAmount - debtPayout
  }

  return {
    cashyPayout,
    debtPayout,
    remainingDebt: opts.debtAmount - debtPayout,
    debtAmount: opts.debtAmount,
  }
}

const StyledButton = styled(Button)`
  color: var(--button-secondary-color, #fff);
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.05rem;
`

export default GeneralInfo
