import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { Mutation, MutationUpdatePartialRedemptionArgs } from '@/schemaTypes'

const UPDATE_PARTIAL_REDEMPTION = gql`
  mutation updatePartialRedemption($args: PartialRedemptionArgs!) {
    updatePartialRedemption(args: $args) {
      _id
    }
  }
`

type TData = { updatePartialRedemption: Mutation['updatePartialRedemption'] }
type TVariables = MutationUpdatePartialRedemptionArgs

export function useUpdatePartialRedemption(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const [updatePartialRedemption] = useMutation<TData, TVariables>(
    UPDATE_PARTIAL_REDEMPTION,
    {
      refetchQueries: ['getDealById'],
      ...opts,
    },
  )

  return updatePartialRedemption
}
