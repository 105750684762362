import gql from 'graphql-tag'
import { fragments, paymentFragments } from '@/hooks/fragments'
import { itemFragments } from './itemFragments'
import { noteFragments } from './noteFragments'

export const dealValuesFragment = {
  dealValuesEntry: gql`
    fragment DealValuesEntryFragment on DealValuesEntry {
      calculationPaybackAmount
      calculationPayoutAmount
      durationInDays
      paybackAmount
      payoutAmount
      overwrittenPayoutAmount
      shouldOverwritePayoutAmount
    }
  `,
}

export const feeFragments = {
  usedFeeDefinitions: gql`
    fragment UsedFeeDefinitionFragment on UsedFeeDefinition {
      itemCategoryId
      storageLabel
      feeWeight
      calculatedFeeDefinitions {
        ...CalculatedFeeDefinitionFragment
      }
    }
    ${fragments.calculatedFeeDefinition}
  `,
}

export const dealCalculationFragments = {
  dealCalculation: gql`
    fragment DealCalculationFragment on DealCalculation {
      date
      dealValuesEntry {
        ...DealValuesEntryFragment
      }
      itemsValuesEntries {
        ...ItemValuesEntryFragment
      }
      loanToValuesEntryId
      appliedUsedFeeDefinitions {
        ...UsedFeeDefinitionFragment
      }
    }
    ${dealValuesFragment.dealValuesEntry}
    ${itemFragments.itemValuesEntry}
    ${feeFragments.usedFeeDefinitions}
  `,
  carDealCalculation: gql`
    fragment CarDealCalculationFragment on CarDealCalculation {
      date
      noValuation
      valuation {
        amount
        vehicleProperties {
          name
          value
        }
      }
      competitiveVehiclesForSale
      dealValuesEntry {
        ...DealValuesEntryFragment
      }
      itemsValuesEntries {
        ...ItemValuesEntryFragment
      }

      loanToValuesEntryId
      appliedUsedFeeDefinitions {
        ...UsedFeeDefinitionFragment
      }
    }
    ${dealValuesFragment.dealValuesEntry}
    ${itemFragments.itemValuesEntry}
    ${feeFragments.usedFeeDefinitions}
  `,
  customDealCalculation: gql`
    fragment CustomDealCalculationFragment on CustomDealCalculation {
      payoutAmount
      dealType
      token
      date
      dealValuesEntry {
        ...DealValuesEntryFragment
      }
      itemsValuesEntries {
        ...ItemValuesEntryFragment
      }
      appliedUsedFeeDefinitions {
        ...UsedFeeDefinitionFragment
      }
    }
    ${dealValuesFragment.dealValuesEntry}
    ${itemFragments.itemValuesEntry}
    ${feeFragments.usedFeeDefinitions}
  `,
  dealValorizationCalculation: gql`
    fragment DealValorizationCalculationFragment on DealValorizationCalculation {
      payoutAmount
      paybackCosts {
        title
        description
        netAmount
        grossAmount
      }
      customerDebt
      surplusAmount
      valorizationFeeRate
      valorizationFeeNet
      valorizationFeeGross
      auctionRevenues {
        itemId
        finalPrice
        storageLabel
        title
      }
    }
  `,
}

export const dealEventFragments = {
  baseEvent: gql`
    fragment BaseEventFragment on BaseDealEvent {
      __typename
      createdAt
      createdAt
      timestamp
      displayName
    }
  `,

  bookedEvent: gql`
    fragment BookedEventFragment on DealBookedEvent {
      dealCalculation {
        ...DealCalculationFragment
      }
    }
    ${dealCalculationFragments.dealCalculation}
  `,
  verifiedEvent: gql`
    fragment VerifiedEventFragment on DealVerifiedEvent {
      dealCalculation {
        ...DealCalculationFragment
      }
    }
    ${dealCalculationFragments.dealCalculation}
  `,
  reviewingEvent: gql`
    fragment ReviewingEventFragment on DealReviewingEvent {
      issuanceCertificate {
        url
        contentType
      }
    }
  `,
  paybackConfirmedEvent: gql`
    fragment PaybackConfirmedEventFragment on DealPaybackConfirmedEvent {
      paymentType {
        ...AnyPaymentTypeFragment
      }
      dealCalculation {
        ...DealCalculationFragment
      }
    }
    ${dealCalculationFragments.dealCalculation}
    ${paymentFragments.anyPaymentType}
  `,
  extensionConfirmedEvent: gql`
    fragment ExtensionConfirmedEventFragment on DealExtensionConfirmedEvent {
      dealCalculation {
        ...DealCalculationFragment
      }
      childCalculation {
        ...DealCalculationFragment
      }
      paymentType {
        ...AnyPaymentTypeFragment
      }
      dueAmount
      partialRedemptionAmount
      overwrittenPartialRedemptionAmount
      itemsExtensionAnswers {
        storageLabel
        answers {
          questionId
          selectedOptionIndex
          rangeValue
        }
      }
    }
    ${dealCalculationFragments.dealCalculation}
    ${paymentFragments.anyPaymentType}
  `,
  onSellEvent: gql`
    fragment OnSellEventFragment on DealOnSellEvent {
      valueAddedTaxRate
      differenceTaxRate
      valorizationFeeRate
    }
  `,
  closedEvent: gql`
    fragment ClosedEventFragment on DealClosedEvent {
      dealCalculation {
        ...DealCalculationFragment
      }
      valorizationFeeNet
      valorizationFeeGross
      isClosedByAuctionId
    }
    ${dealCalculationFragments.dealCalculation}
  `,
}

export const dealFragments = {
  event: gql`
    fragment DealEventFragment on DealEvent {
      ...BaseEventFragment
      ...BookedEventFragment
      ...VerifiedEventFragment
      ...ReviewingEventFragment
      ...PaybackConfirmedEventFragment
      ...ExtensionConfirmedEventFragment
      ...OnSellEventFragment
      ...ClosedEventFragment
    }
    ${dealEventFragments.baseEvent}
    ${dealEventFragments.bookedEvent}
    ${dealEventFragments.reviewingEvent}
    ${dealEventFragments.verifiedEvent}
    ${dealEventFragments.paybackConfirmedEvent}
    ${dealEventFragments.extensionConfirmedEvent}
    ${dealEventFragments.onSellEvent}
    ${dealEventFragments.closedEvent}
  `,
  dealFinalValues: gql`
    fragment DealFinalValuesFragment on DealFinalValues {
      dealType
      durationInDays
      shouldOverwritePayoutAmount
      payoutAmount
      isOnlineExtensionPrevented
      minPartialRedemptionAmount
      partialRedemptionIntervalsCount
      partialRedemptionSetUpBy {
        ... on Employee {
          firstname
          lastname
        }
        ... on SystemOperation {
          name
        }
      }
    }
  `,
  transportData: gql`
    fragment TransportDataFragment on TransportData {
      transportMode
      premiumShippingData {
        fromHourUTC
        toHourUTC
        date
        address {
          ...AddressFragment
        }
      }
      standardShippingData {
        address {
          ...AddressFragment
        }
        sentShippingLabels {
          direction
          shippingPartner
          labelCreateDate
          senderAddress {
            houseNumber
            stairway
            door
            floor
            street
            city
            state
            countryCode
            country
            zipCode
          }
          senderName
          receiverAddress {
            houseNumber
            stairway
            door
            floor
            street
            city
            state
            countryCode
            country
            zipCode
          }
          receiverName
          labelFile {
            url
            contentType
            filename
          }
          trackingCode
          trackingLink
        }
      }
      storeboxData {
        address {
          ...StoreboxPickupAddressFragment
        }
        slot {
          start
          end
        }
        bookedAt
      }
    }
    ${fragments.address}
    ${fragments.storeboxPickupAddress}
  `,
  pawnData: gql`
    fragment PawnDataFragment on PawnData {
      finalMaxPawnDate
      pawnNumber
      pawnTicketLink {
        ...FileMetadataFragment
      }
      billNumber
      billLink {
        ...FileMetadataFragment
      }
      userAgreementDocuments {
        ...PawnDocumentFragment
      }
      powerOfAttorneyDocuments {
        ...PawnDocumentFragment
      }
      paybackData {
        ...PaymentDataFragment
      }
      extensionChildId
      extensionParentId
      buyBackChildId
      buyBackParentId
      surplusCashBookCashFlowIds
      reservedPaybackData {
        isExtension
        date
        paymentType
        feeAmount
        partialRedemptionAmount
        dueAmount
      }
    }
    ${fragments.paymentData}
    ${fragments.fileMetadata}
    ${fragments.pawnDocument}
  `,
  purchaseData: gql`
    fragment PurchaseDataFragment on PurchaseData {
      receiptNumber
      receiptLink {
        url
        contentType
      }
      receiptLinkWithoutIdentity {
        url
        contentType
      }
    }
  `,
  dealItem: gql`
    fragment DealItemFragment on DealItem {
      _id
      createdAt
      updatedAt
      title
      material
      ean
      imei
      serialNumber
      itemSpecification
      algoliaReference
      variantId
      unconfirmedPropertyIds
      productVariant {
        id
        name
        product {
          category {
            parentCategories {
              name
            }
          }
          name
        }
        propertiesValues {
          ... on ProductVariantPropertyTextValue {
            propertyDefinitionId
            propertyDefinition {
              _id
              type
              impactsLook
              name {
                allTranslations {
                  text
                  languageCode
                }
              }
              key
              valueFormatting {
                suffix
              }
            }
            value {
              translation {
                allTranslations {
                  text
                  languageCode
                }
              }
            }
          }
          ... on ProductVariantPropertyNumberValue {
            propertyDefinitionId
            propertyDefinition {
              _id
              type
              impactsLook
              name {
                allTranslations {
                  text
                  languageCode
                }
              }
              key
              valueFormatting {
                suffix
              }
            }
            value {
              numberValue
            }
          }
          ... on ProductVariantPropertyBooleanValue {
            propertyDefinitionId
            propertyDefinition {
              _id
              type
              impactsLook
              name {
                allTranslations {
                  text
                  languageCode
                }
              }
              key
              valueFormatting {
                suffix
              }
            }
            value {
              booleanValue
            }
          }
        }
      }
      itemCategoryId
      isInvestmentGold
      customDealId
      isContinueUsing
      customRequestId
      usedGrade
      questions {
        _id
        validFrom
        validTo
        order
        questionType
        valueMode
        titleKey
        infoKey
        isInternal
        singleChoiceOptions {
          labelKey
          infoKey
          categoryValues
          conditionValue
        }
        rangeData {
          minValue
          maxValue
          unitPrice
          unitKey
        }
        predictionTag
        itemCategoryIds
      }
      answers {
        questionId
        selectedOptionIndex
        rangeValue
      }
      media {
        ...FileMetadataFragment
      }
      storageFacilityId
      storageLabel
      storageHistory {
        storageUnits {
          lvl1
          lvl2
          lvl3
          lvl4
        }
        createdAt
        timestamp
        storageStatus
        employeeId
        note
      }
      loanToValuesEntryId
      internalSaleData {
        price
        date
        purchasedDirectly
        isCashyPrice
        buyerData {
          partnerId
          name
        }
        priceHistories {
          price
        }
      }
      policeNumber
      isDamageCase
      isForPersonalUse
      itemCategoryId
      itemCategory {
        _id
        name
        parentId
        algoliaCategorySlug
      }
      values {
        selected {
          ...ItemValuesEntryFragment
        }
        verified {
          ...ItemValuesEntryFragment
        }
        closed {
          ...ItemValuesEntryFragment
        }
      }
      itemValorizationCalculation {
        ...ItemValorizationCalculationFragment
      }
      piceaTest {
        disable
      }
      piceaUploadLink {
        url
        contentType
      }
      entrupyCertificateUrl
      checkedBy
      checkedAt
      dealsHistory {
        dealId
        values {
          verified {
            loanToValue
            payoutAmount
          }
        }
      }
      events {
        ...ItemEventFragment
      }
      vehicleData {
        vehicleProperties {
          make
          model
          regdate
          body
          facelift
          seats
          engine
          transmission
          odometer
          otherVehicleProperties {
            value
            name
          }
        }
        vin
        indicataId
        carPriceLastFetched
        vinLastFetched
        hasTypeCertificate
        hasApprovalCertificate
        has57aGutachten
        has57aGutachtenDue
        hasSecondKey
        hasSaleContract
        licensePlate
        spareTireCount
        ksvQueryHistories {
          type
          vin
          queryAt
          meta
        }
      }
      notes {
        ...NoteFragment
      }
    }
    ${itemFragments.itemEvent}
    ${itemFragments.itemValuesEntry}
    ${itemFragments.itemValorizationCalculation}
    ${fragments.fileMetadata}
    ${noteFragments.notes}
  `,
}
