import classNames from 'classnames'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchProducts } from '@/domains/products/hooks/searchProducts'
import Input from '@/redesign/components/Input/Input'
import { ProductVariant, QuerySearchProductsArgs } from '@/schemaTypes'
import ProductVariantSelector from './ProductVariantSelector'
import ProductsTable from './ProductsTable'

export interface ProductSelectorProps {
  allowProductsWithoutValidPrice: boolean
  onSelectItem: (
    item:
      | { productObjectID: string }
      | { productVariant: ProductVariant; itemCategoryId: string },
  ) => void
  className?: string
}

export function ProductSelector(props: ProductSelectorProps) {
  const { onSelectItem } = props

  return (
    <div className={classNames('min-h-mc flex flex-col', props.className)}>
      <NewProductDatabaseSearch
        allowProductsWithoutValidPrice={props.allowProductsWithoutValidPrice}
        onSelectProductVariant={(
          productVariant: ProductVariant,
          itemCategoryId: string,
        ) => onSelectItem({ productVariant, itemCategoryId })}
      />
    </div>
  )
}

export default ProductSelector

interface NewProductDatabaseSearchProps {
  allowProductsWithoutValidPrice: boolean
  onSelectProductVariant: (
    productVariant: ProductVariant,
    itemCategoryId: string,
  ) => void
}

function NewProductDatabaseSearch({
  onSelectProductVariant,
  allowProductsWithoutValidPrice,
}: NewProductDatabaseSearchProps) {
  const { t } = useTranslation()
  const [searchTerm, setSearchTerm] = useState('')
  const [productId, setProductId] = useState<string | null>(null)

  return (
    <div className="mt-6 flex-grow flex flex-col">
      {!productId && (
        <>
          <Input
            className="mb-4"
            placeholder={t('search')}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <NewProductsTable
            searchTerm={searchTerm}
            onSelectProduct={setProductId}
          />
        </>
      )}

      {productId && (
        <ProductVariantSelector
          allowProductsWithoutValidPrice={allowProductsWithoutValidPrice}
          productId={productId}
          onClickBack={() => setProductId(null)}
          onSelectProductVariant={onSelectProductVariant}
        />
      )}
    </div>
  )
}

interface NewProductsTableProps {
  searchTerm: string
  onSelectProduct: (productId: string) => void
}

function NewProductsTable({
  searchTerm,
  onSelectProduct,
}: NewProductsTableProps) {
  const [searchTime, setSearchTime] = useState(new Date())
  const [prevSearchTerm, setPrevSearchTerm] = useState(searchTerm)
  if (searchTerm !== prevSearchTerm) {
    setSearchTime(new Date())
    setPrevSearchTerm(searchTerm)
  }

  const variables = useMemo(
    (): QuerySearchProductsArgs => ({
      args: {
        search: searchTerm,
        paginationArgs: {
          limit: 50,
        },
        createdBefore: searchTime,
      },
    }),
    [searchTerm, searchTime],
  )

  const { queryResult } = useSearchProducts({
    variables,
  })

  return (
    <ProductsTable
      products={
        queryResult.data?.searchProducts.nodes.map((node) => ({
          id: node._id,
          name: node.name,
          categoryName: node.category.name,
        })) ?? []
      }
      loading={queryResult.loading}
      onSelectProduct={onSelectProduct}
    />
  )
}
