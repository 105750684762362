import { Product } from '@/schemaTypes'
import { MainCategoryType } from '@/types'

export function belongsToMainCategory(
  product: Product,
  category: MainCategoryType,
): boolean {
  return !!product?.category?.parentCategories.some(
    (parentCategory) => parentCategory.name === category,
  )
}
