import { useUpdateDealItemAnswersWithoutRecalculation } from '../../../hooks'
import { Button } from '@material-ui/core'
import { Paper, Typography } from '@mui/material'
import { isEqual } from 'lodash'
import { useContext, useEffect, useState } from 'react'
import { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { useFormatVariantProperties } from '@/components/ProductSelector/helper'
import { Context } from '@/context'
import {
  useModifyDealItemsWithWarning,
  useUpdateDealItemAnswers,
} from '@/domains/deals/hooks'
import { useCalculateDealItem } from '@/domains/deals/hooks/calculateDealItem'
import { useMutationShowingErrors } from '@/hooks'
import { Deal, EBusinessUnit, Item, ItemAnswerArgs } from '@/schemaTypes'
import { MainCategoryType } from '@/types'
import { isDealVerified } from '@/utils/deal'
import {
  getVehicleCategorieId,
  isCustomerContinueUsingItem,
} from '@/utils/misc'
import { belongsToMainCategory } from '@/utils/product'
import ItemCalculation from './ItemCalculation'
import ItemCategoryQuestions from './ItemCategoryQuestions'

interface ChangeItemQuestionsAnswersProps {
  deal: Deal
  item: Item
}

const ChangeItemQuestionsAnswers: FunctionComponent<
  ChangeItemQuestionsAnswersProps
> = (props) => {
  const { deal, item } = props

  const formattedProperties = useFormatVariantProperties(item.productVariant)

  const { showConfirmModal, closeConfirmModal } = useContext(Context)
  const history = useHistory()
  const { t } = useTranslation()

  const modifyDealItemsWithWarning = useModifyDealItemsWithWarning(deal)
  const { calculateDealItem, queryResult: calculationQueryResult } =
    useCalculateDealItem()

  const [updateDealItemMutation] = useUpdateDealItemAnswers()
  const [updateDealItemAnswersWithoutRecalculation] =
    useUpdateDealItemAnswersWithoutRecalculation()

  const updateDealItem = useMutationShowingErrors({
    mutation: updateDealItemMutation,
  })
  const updateDealItemWithoutRecalculation = useMutationShowingErrors({
    mutation: updateDealItemAnswersWithoutRecalculation,
  })

  const dealsHistoryEntry = item?.dealsHistory.find(
    (entry) => entry.dealId === deal._id,
  )

  const [itemAnswerArgs, setItemAnswerArgs] = useState<ItemAnswerArgs[]>(
    dealsHistoryEntry?.answers ?? [],
  )

  const [isCalculationNeeded, setIsCalculationNeeded] = useState(false)

  const isDirectSaveAllowed =
    isDealVerified(deal) && deal.businessUnit !== EBusinessUnit.Car

  useEffect(() => {
    if (!isEqual(dealsHistoryEntry?.answers, itemAnswerArgs)) {
      setIsCalculationNeeded(true)
    }
  }, [itemAnswerArgs, dealsHistoryEntry])

  if (
    !dealsHistoryEntry ||
    !dealsHistoryEntry.questions ||
    !dealsHistoryEntry.answers
  ) {
    return null
  }

  const onItemCalculation = async () => {
    if (deal.businessUnit === EBusinessUnit.Car) {
      await calculateDealItem({
        variables: {
          companyId: deal.company._id,
          answers: itemAnswerArgs,
          durationInDays:
            deal.dealFinalValues.durationInDays ||
            deal.company.configuration.minimumPawnDuration,
          itemCategoryId: getVehicleCategorieId(
            dealsHistoryEntry.itemCategoryId,
            isCustomerContinueUsingItem(itemAnswerArgs),
          ),
          date: dealsHistoryEntry.values.verified?.date,
          customPayoutAmount: item?.vehicleData?.indicataId
            ? undefined
            : dealsHistoryEntry.values.verified?.payoutAmount,
          existingCurrentMarketValue:
            dealsHistoryEntry?.values?.verified?.currentMarketValue,
          existingPredictedMarketValue:
            dealsHistoryEntry?.values?.verified?.predictedMarketValue,
          useOldValuesOfPrediction: true,
          vehicleData: {
            indicataId: item.vehicleData?.indicataId,
            vehicleProperties: {
              make: item.vehicleData?.vehicleProperties?.make ?? '',
              odometer: item.vehicleData?.vehicleProperties?.odometer ?? 0,
              regdate: item.vehicleData?.vehicleProperties?.regdate ?? '',
            },
          },
        },
      })
    } else {
      await calculateDealItem({
        variables: {
          companyId: deal.companyId,
          durationInDays: deal.dealFinalValues.durationInDays,
          algoliaReference:
            dealsHistoryEntry?.algoliaReference ??
            (!dealsHistoryEntry?.variantId ? 'custom' : undefined),
          answers: itemAnswerArgs,
          itemCategoryId: dealsHistoryEntry?.itemCategoryId,
          date: dealsHistoryEntry?.values.verified?.date,
          material: dealsHistoryEntry?.material,
          existingCurrentMarketValue:
            dealsHistoryEntry?.values?.verified?.currentMarketValue,
          existingPredictedMarketValue:
            dealsHistoryEntry?.values?.verified?.predictedMarketValue,
          useOldValuesOfPrediction: true,
          variantId: dealsHistoryEntry?.variantId,
        },
      })
    }
    setIsCalculationNeeded(false)
  }

  const onSave = async () => {
    modifyDealItemsWithWarning(async () => {
      await updateDealItem({
        variables: {
          dealId: deal._id,
          args: {
            itemId: item?._id,
            answers: itemAnswerArgs,
          },
        },
      })

      history.push(`/inApp/items/${item?._id}`)
    })
  }

  const onUpdateItemWithoutRecalculation = async () => {
    showConfirmModal({
      title: `${t('items_edited_answers_warn')}`,
      leftButtonText: t('no'),
      rightButtonText: t('yes'),
      leftButtonAction: () => {
        closeConfirmModal()
      },
      rightButtonAction: () => {
        closeConfirmModal()
        updateDealItemWithoutRecalculation({
          variables: {
            dealId: deal._id,
            args: {
              itemId: item?._id,
              answers: itemAnswerArgs,
            },
          },
        })

        history.push(`/inApp/items/${item?._id}`)
      },
    })
  }

  const isCalculationDone = Boolean(
    calculationQueryResult.data?.itemCalculation?.itemValuesEntry,
  )

  return (
    <Paper className="u-p-sm u-12/12 u-mb-sm">
      <Typography
        variant="h5"
        gutterBottom
        style={{
          textAlign: 'left',
        }}
      >
        {item.productVariant?.product?.name || dealsHistoryEntry.title}
      </Typography>

      <div className="flex flex-wrap">
        {!belongsToMainCategory(
          item.productVariant?.product,
          MainCategoryType.JEWELLERY,
        ) &&
          formattedProperties?.map(({ title, value }, index, list) => {
            return (
              <div className="flex text-sm">
                <span className="text-gray-500 mr-2">{title}:</span>
                <span className="font-bold">{value}</span>
                {/* not using tailwinds divide-x, as it is not centered:
                    https://github.com/tailwindlabs/tailwindcss/discussions/5220 */}
                {index != list.length - 1 && list.length > 1 && (
                  <span className="mx-6 text-gray-300">|</span>
                )}
              </div>
            )
          })}
      </div>

      <ItemCategoryQuestions
        itemCategoryId={dealsHistoryEntry.itemCategoryId}
        questions={dealsHistoryEntry?.questions}
        itemAnswerArgs={itemAnswerArgs}
        setItemAnswerArgs={setItemAnswerArgs}
        isReadOnly={false}
        omitDeactivatedQuestionsForNewProducts={Boolean(
          dealsHistoryEntry?.variantId,
        )}
      />

      {!isDirectSaveAllowed && (
        <>
          {(dealsHistoryEntry?.values.verified || isCalculationDone) && (
            <ItemCalculation
              verifiedItemValuesEntry={dealsHistoryEntry?.values.verified}
              calculatedItemValuesEntry={
                isCalculationNeeded
                  ? undefined
                  : calculationQueryResult?.data?.itemCalculation
                      ?.itemValuesEntry
              }
            />
          )}

          {isCalculationNeeded && (
            <Button
              onClick={onItemCalculation}
              variant="contained"
              color="primary"
              className="u-mb-sm u-mt-sm"
              disabled={calculationQueryResult.loading}
            >
              {t('calculate_values')}
            </Button>
          )}

          {!isCalculationNeeded && isCalculationDone && (
            <Button
              className="u-mb-sm u-mt-sm"
              onClick={onSave}
              variant="contained"
              color="primary"
            >
              {t('update_item')}
            </Button>
          )}
        </>
      )}

      {isDirectSaveAllowed && (
        <Button
          onClick={onUpdateItemWithoutRecalculation}
          variant="contained"
          color="primary"
          className="u-mb-sm u-mt-sm"
        >
          {t('save_without_calculation')}
        </Button>
      )}
    </Paper>
  )
}

export default ChangeItemQuestionsAnswers
