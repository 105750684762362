import { QueryHookOptions, gql, useQuery } from '@apollo/client'
import { Query, QueryProductVariantArgs } from '@/gql/graphql'
import { useShowErrorsPopup } from '@/hooks'

const QUERY = gql`
  query getProductVariantForItemEvaluation($args: GetProductVariantArgs!) {
    productVariant(args: $args) {
      id
      name
      product {
        categoryId
        category {
          parentCategories {
            name
          }
        }
        name
        price {
          ... on ProductPriceManualPreciousMetalMode {
            mode
            material

            variants {
              variantId
              materialMetric {
                weight
                alloy
              }
            }
          }
        }
      }
    }
  }
`

type Data = { productVariant: Query['productVariant'] }
type Variables = QueryProductVariantArgs
export default function useProductVariantForItemEvaluation(
  opts?: QueryHookOptions<Data, Variables>,
) {
  const queryResult = useQuery<Data, Variables>(QUERY, opts)

  useShowErrorsPopup(queryResult.error)

  return {
    queryResult,
    productVariant: queryResult.data?.productVariant,
  }
}
