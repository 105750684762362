import { CustomDealItemsList } from '..'
import { CustomDealItemDetail } from '../CustomDealItemDetail'
import {
  CustomDealItemSelect,
  ItemDraftQuestionsFormData,
} from '../CustomDealItemSelect'
import { Box, Button, DialogContent, Typography } from '@material-ui/core'
import { FormikErrors } from 'formik'
import { Dialog } from 'primereact/dialog'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import AddVehicle, {
  ItemDraftVehicleFormData,
} from '@/domains/items/components/Item/ItemEvaluation/AddVehicle'
import CustomItemDraft, {
  CustomItemDraftFormData,
} from '@/redesign/domains/customDeals/components/CustomDealItems/CustomItemDraft/CustomItemDraft'
import useProductVariantForUpdateCustomItem from '@/redesign/domains/customDeals/components/CustomDealItems/CustomItemDraft/useProductVariantForUpdateCustomItem'
import {
  CustomDealCreateArgs,
  CustomDealItemDataEntry,
  ECustomItemFoundComparableOffers,
  EDealType,
  Scalars,
} from '@/schemaTypes'

type CustomDealItem = Omit<CustomDealItemDataEntry, '_id'> & { _id?: string }

interface Props {
  items: CustomDealItem[]
  dealType: EDealType
  durationInDays: number
  minimumPawnDuration?: number
  companyId: Scalars['ObjectId']
  customDealId?: string
  isCarPawn: boolean
  onRemoveItem: (index: number) => void
  onAddItem: (item: Omit<CustomDealItem, '_id' | 'itemCategory'>) => void
  onUpdateItem: (item: CustomDealItem, editedIndex: number) => void
  onChangeField: (field: string, value: unknown) => void
  errors?: FormikErrors<CustomDealCreateArgs>['items']
  isLoading: boolean
}

enum DIALOG {
  NORMAL_ITEM = 'NORMAL_ITEM',
  CUSTOM_ITEM = 'CUSTOM_ITEM',
  VEHICLE_ITEM = 'VEHICLE_ITEM',
  VIEW_ITEM = 'VIEW_ITEM',
}

const getTypeDialogOfItem = (item: CustomDealItem) => {
  if (item?.vehicleData?.indicataId) return DIALOG.VEHICLE_ITEM
  if (item.algoliaReference) return DIALOG.NORMAL_ITEM
  return DIALOG.CUSTOM_ITEM
}

export function CustomDealItems(props: Props) {
  const {
    items,
    dealType,
    durationInDays,
    minimumPawnDuration,
    isCarPawn,
    onRemoveItem,
    onUpdateItem,
    onAddItem,
    onChangeField,
    errors,
    companyId,
    isLoading,
  } = props
  const { t } = useTranslation()

  const [editedItem, setEditedItem] = useState<CustomDealItem>()
  const [editedIndex, setEditedIndex] = useState<number>()
  const [dialogMode, setDialogMode] = useState<DIALOG>()

  const shouldShowAddVehicle = !items.length
  const shouldShowAddNormalAndCustomItem =
    !isCarPawn && (!items.length || items.some((item) => !item.vehicleData))

  const { productVariant } = useProductVariantForUpdateCustomItem({
    variables: {
      args: {
        productVariantId: editedItem?.variantId,
      },
    },
    skip: !editedItem?.variantId,
  })

  const onEditItem = (index: number) => {
    const item = items[index]
    setEditedItem(item)
    setEditedIndex(index)
    setDialogMode(getTypeDialogOfItem(item))
  }

  const onViewItem = (index: number) => {
    const item = items[index]
    setEditedItem(item)
    setEditedIndex(index)
    setDialogMode(DIALOG.VIEW_ITEM)
  }

  const onEditItemFromView = () => {
    setDialogMode(getTypeDialogOfItem(editedItem!))
  }

  const handleCloseDialog = () => {
    setEditedItem(undefined)
    setDialogMode(undefined)
    setEditedIndex(undefined)
  }

  const onOpenCustomItemDialog = () => {
    setDialogMode(DIALOG.CUSTOM_ITEM)
  }

  const addOpenNormalItemDialog = () => {
    setDialogMode(DIALOG.NORMAL_ITEM)
  }

  const onOpenVehicleItemDialog = () => {
    setDialogMode(DIALOG.VEHICLE_ITEM)
  }

  const onSubmitItem = (
    item: Omit<CustomDealItem, '_id' | 'itemCategory'> & { _id?: string },
  ) => {
    if (editedIndex !== undefined) {
      onUpdateItem(item as CustomDealItem, editedIndex)
    } else if (!item._id) {
      onAddItem(item)
    }

    handleCloseDialog()
  }

  const onSaveCustomItem = (formData: CustomItemDraftFormData) => {
    const item = editedIndex !== undefined ? items[editedIndex] : undefined

    const id = item && '_id' in item ? item._id : undefined

    // TODO: update type here later
    const newItem = {
      _id: id,
      policeNumber: formData.policeNumber,
      title: formData.title,
      pawnPayoutAmount: formData.payoutAmount,
      purchasePayoutAmount: formData.payoutAmount,
      counterofferPayoutAmount: formData.counterofferPayoutAmount,
      itemCategoryId: formData.itemCategoryId,
      mediaUploads: formData.mediaUploads,
      answers: formData.answers,
      formAnswers: formData.formAnswers,
      ...(formData.vehicleProperties && {
        vehicleData: { vehicleProperties: formData.vehicleProperties },
      }),
      foundComparableOffers: formData.foundComparableOffers,
      overwrittenItemValue: formData.overwrittenItemValue,
      priceFindings: formData.priceFindings
        ?.filter((c) => c.price && c.url)
        ?.map((priceFinding) => ({
          url: priceFinding.url,
          price: Number(priceFinding.price) || null,
        })),
      foundOffersEvaluationFactor: formData.foundOffersEvaluationFactor,
      variantId: formData.variantId,
      unconfirmedPropertyIds: formData.unconfirmedPropertyIds,
    }

    onSubmitItem(newItem)
  }

  const onSaveNormalItem = (formData: ItemDraftQuestionsFormData) => {
    const item = editedIndex !== undefined ? items[editedIndex] : undefined

    const id = item && '_id' in item ? item._id : undefined
    onSubmitItem({
      _id: id,
      title: formData.title,
      algoliaReference: formData.algoliaReference,
      variantId: formData.variantId,
      unconfirmedPropertyIds: formData.unconfirmedPropertyIds,
      material: formData.material,
      ean: formData.ean,
      pawnPayoutAmount: formData.payoutAmountPawn ?? 0,
      purchasePayoutAmount: formData.payoutAmountPurchase ?? 0,
      itemCategoryId: formData.itemCategoryId,
      answers: formData.answers,
    })
  }

  const onSaveVehicleItem = (formData: ItemDraftVehicleFormData) => {
    const newItem = {
      title: formData.title,
      itemCategoryId: formData.itemCategoryId,
      vehicleData: formData.vehicleData,
      answers: formData.answers,
      pawnPayoutAmount: formData.customPayoutAmount ?? 0,
      purchasePayoutAmount: formData.customPayoutAmount ?? 0,
      note: '',
    }

    onSubmitItem(newItem)
  }

  const onChangeDealType = (dealType: EDealType) => {
    onChangeField('dealType', dealType)
    onChangeField(
      'durationInDays',
      dealType === EDealType.Pawn ? minimumPawnDuration : 0,
    )
  }

  return (
    <div className="u-p-sm u-12/12 u-mb-sm">
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <div>
          <Typography variant="h6" gutterBottom>
            {t('item.plural')}
          </Typography>
        </div>
        <div>
          {shouldShowAddNormalAndCustomItem && (
            <Button
              variant="outlined"
              color="primary"
              size="small"
              onClick={addOpenNormalItemDialog}
              className="u-mr-sm"
            >
              {t('item.add_item')}
            </Button>
          )}

          {shouldShowAddNormalAndCustomItem && (
            <Button
              variant="outlined"
              color="primary"
              size="small"
              onClick={onOpenCustomItemDialog}
              className="u-mr-sm"
            >
              {t('add_custom_item')}
            </Button>
          )}

          {shouldShowAddVehicle && (
            <Button
              variant="outlined"
              color="primary"
              size="small"
              onClick={onOpenVehicleItemDialog}
              disabled={items.length >= 1}
            >
              {t('add_car_data')}
            </Button>
          )}
        </div>
      </Box>
      <CustomDealItemsList
        items={items}
        dealType={dealType}
        onRemoveItem={onRemoveItem}
        onEditItem={onEditItem}
        onViewItem={onViewItem}
      />
      {typeof errors === 'string' && (
        <Typography variant="body2" color="error">
          {t(errors)}
        </Typography>
      )}

      <Dialog
        header={
          dialogMode === DIALOG.VIEW_ITEM
            ? t('view_item')
            : (editedItem as any)?._id
              ? t('update_item')
              : t('item.add_item')
        }
        visible={!!dialogMode}
        style={{ width: '70vw' }}
        breakpoints={{ '1400px': '100vw' }}
        onHide={() => handleCloseDialog()}
        draggable={false}
        blockScroll={true}
      >
        {dialogMode === DIALOG.VIEW_ITEM && editedItem && (
          <CustomDealItemDetail
            item={editedItem}
            dealType={dealType}
            onEditItem={onEditItemFromView}
            productVariant={productVariant}
          />
        )}

        {dialogMode === DIALOG.CUSTOM_ITEM &&
          !isLoading &&
          (!editedItem?.variantId || !productVariant?.isPriceValid) && (
            <CustomItemDraft
              companyId={companyId}
              durationInDays={durationInDays}
              items={items}
              dealType={dealType}
              onChangeDealType={onChangeDealType}
              onSave={onSaveCustomItem}
              editedIndex={editedIndex}
              item={editedItem}
              productVariant={productVariant}
            />
          )}

        {(dialogMode === DIALOG.NORMAL_ITEM ||
          (dialogMode === DIALOG.CUSTOM_ITEM &&
            productVariant?.isPriceValid)) && (
          <DialogContent>
            <CustomDealItemSelect
              companyId={companyId}
              durationInDays={durationInDays}
              minimumPawnDuration={minimumPawnDuration ?? durationInDays}
              onSaveItem={onSaveNormalItem}
              items={items}
              dealType={dealType}
              onChangeDealType={onChangeDealType}
              onSaveCustomItem={onSaveCustomItem}
            />
          </DialogContent>
        )}

        {dialogMode === DIALOG.VEHICLE_ITEM && (
          <AddVehicle
            companyId={companyId}
            durationInDays={durationInDays}
            onSubmit={onSaveVehicleItem}
          />
        )}
      </Dialog>
    </div>
  )
}

export const onlyOverwriteIsPossible = (
  foundComparableOffers: ECustomItemFoundComparableOffers,
) => {
  return (
    foundComparableOffers === ECustomItemFoundComparableOffers.OneOrTwo ||
    foundComparableOffers === ECustomItemFoundComparableOffers.NoOffersFound
  )
}

export function getItemValuationFactor(
  foundComaparableOffers: ECustomItemFoundComparableOffers,
) {
  switch (foundComaparableOffers) {
    case ECustomItemFoundComparableOffers.LessThanTen:
      return 0.8
    case ECustomItemFoundComparableOffers.MoreThanTen:
      return 1
    default:
      return 0.4
  }
}
