import {
  includeHidedCategories,
  useGetItemCategories,
} from '../../../domains/itemCategories/hooks'
import styled from '@emotion/styled'
import { Box, Button, Typography } from '@material-ui/core'
import ArrowBackIos from '@material-ui/icons/ArrowBackIos'
import dayjs from 'dayjs'
import { Form, FormikProvider, useFormik } from 'formik'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useHistory } from 'react-router-dom'
import { ReactSVG } from 'react-svg'
import * as Yup from 'yup'
import CategorySelector from '@/components/CategorySelector'
import SelectOptionInput from '@/components/SelectOptionInput'
import { EProductProperyDefinitionSuffix } from '@/domains/productPropertyDefinitions/components/enums'
import { useGetProductPropertyDefinitions } from '@/domains/productPropertyDefinitions/hooks/getProductPropertyDefinitions'
import { ProductEditAvailableProperty } from '@/domains/products/components/ProductEditAvailableProperty'
import { ProductImages } from '@/domains/products/components/ProductImages'
import { ProductKeywords } from '@/domains/products/components/ProductKeywords'
import { ProductPublishConfirmPopup } from '@/domains/products/components/ProductModals'
import { ProductVariants } from '@/domains/products/components/ProductVariants'
import { useCreateProduct } from '@/domains/products/hooks/createProduct'
import { useGetProduct } from '@/domains/products/hooks/getProduct'
import { useUpdateProduct } from '@/domains/products/hooks/updateProduct'
import { useGetLookVariants } from '@/domains/products/hooks/useGetLookVariants'
import { useGetProductFormData } from '@/domains/products/hooks/useGetProductFormData'
import { isNotEmptyPropertyValue } from '@/domains/products/hooks/useGetPropertyValueErrorMessages'
import {
  ProductAvailableProperty,
  ProductAvailablePropertyTextOptions,
  ProductFormData,
  ProductNumberPropertyValue,
  ProductTranslationPropertyValue,
  ProductVariant,
  PropertyValue,
} from '@/domains/products/interfaces'
import { useMutationShowingErrors } from '@/hooks'
import DeleteIcon from '@/images/deleteOption.svg'
import DatePicker from '@/redesign/components/DatePicker/DatePicker'
import FormikInput from '@/redesign/components/FormikFields/FormikInput/FormikInput'
import {
  ELanguageCode,
  EProductImageMode,
  EProductPropertyType,
  MutationCreateProductArgs,
  ProductAvailablePropertyValuesArgs,
  ProductPropertyDefinition,
  ProductVariantArgs,
  ProductVariantValueArgs,
  ProductVariantValueOptionArgs,
} from '@/schemaTypes'
import { invariant } from '@/utils/invariant'
import { getCombinations } from '@/utils/misc'

interface Props {
  id?: string
}

export const ProductDetails = (props: Props) => {
  const { t } = useTranslation()
  const { id } = props
  const isProductCreation = !id
  const history = useHistory()
  const { product } = useGetProduct({
    variables: {
      productId: id,
      variantsArgs: {
        includeDisabled: true,
      },
    },
    skip: isProductCreation,
  })

  const createProduct = useMutationShowingErrors({
    mutation: useCreateProduct(),
    successMessage: t('product_created_successfully'),
  })

  const updateProduct = useMutationShowingErrors({
    mutation: useUpdateProduct(),
    successMessage: t('product_updated_successfully'),
  })

  const [openPublishConfirmPopup, setOpenPublishConfirmPopup] = useState(false)

  const initialValues = useGetProductFormData(product)

  const formik = useFormik<ProductFormData>({
    initialValues,
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      categoryId: Yup.string().required(
        t('error.error_validation_is_required'),
      ),
      name: Yup.string().required(t('error.error_validation_is_required')),
      manufacturer: Yup.string().required(
        t('error.error_validation_is_required'),
      ),
      isPublished: Yup.boolean().required(
        t('error.error_validation_is_required'),
      ),
      keywords: Yup.array()
        .of(Yup.string())
        .test('unique', t('error.error_validation_is_unique'), (value) => {
          return value ? value.length === new Set(value)?.size : true
        }),
    }),
    onSubmit: (values, actions) => {
      if (
        !values.categoryId ||
        values.isPublished === null ||
        values.isPublished === undefined ||
        !values.manufacturer ||
        !values.name
      ) {
        // Not expected, because these values are validated
        return
      }

      const now = dayjs().toDate()

      const variants = values.variants.map(
        (variant): ProductVariantArgs => ({
          propertiesValues: variant.propertiesValues.map(
            createProductVariantPropertyValue,
          ),
          imageId: variant.imageId,
          ephemeralImageId: variant.ephemeralImageId,
          id: variant.id,
          enabled: variant.enabled,
          releasedAt: variant.releasedAt,
        }),
      )

      const missingVariants: ProductVariantArgs[] = generateMissingVariants(
        values.availableProperties,
        values.variants,
        releasedAt ?? now,
      )
      if (missingVariants.length) {
        variants.push(...missingVariants)
      }

      const createProductVariables: MutationCreateProductArgs = {
        args: {
          name: values.name,
          manufacturer: values.manufacturer,
          imageMode: values.imageMode,
          imageId: values.image?.id,
          ephemeralImageId: values.image?.ephemeralId,
          overviewImageId: values.overviewImage?.id,
          ephemeralOverviewImageId: values.overviewImage?.ephemeralId,
          keywords: values.keywords,
          availableProperties: values.availableProperties.map(
            (availableProperty) => {
              const values: ProductAvailablePropertyValuesArgs = {}
              if (
                availableProperty.propertyDefinitionType ===
                EProductPropertyType.Boolean
              ) {
                return {
                  propertyDefinitionId: availableProperty.propertyDefinitionId,
                  sortPriority:
                    availableProperty.propertyDefinition.sortPriority,
                }
              }
              const variantsPropertyValues = availableProperty.values.map(
                ({ value }) =>
                  createProductVariantPropertyValue({
                    value,
                    propertyDefinition: availableProperty.propertyDefinition,
                  }),
              )
              if (
                availableProperty.propertyDefinitionType ===
                EProductPropertyType.Translation
              ) {
                values.textOptions = variantsPropertyValues.map(
                  (v) => v.value.textOption,
                )
              } else if (
                availableProperty.propertyDefinitionType ===
                EProductPropertyType.Number
              ) {
                values.numberOptions = variantsPropertyValues.map(
                  (v) => v.value.numberOption,
                )
              }
              return {
                values,
                propertyDefinitionId: availableProperty.propertyDefinitionId,
                sortPriority: availableProperty.propertyDefinition.sortPriority,
              }
            },
          ),
          variants,
          isPublished: !values.variants.some((v) => v.enabled)
            ? false
            : values.isPublished,
          categoryId: values.categoryId,
        },
      }
      // when no available properties defined, add default variant to the variants array
      if (
        !createProductVariables.args.availableProperties.length &&
        !createProductVariables.args.variants.length
      ) {
        createProductVariables.args.variants.push({
          propertiesValues: [],
          releasedAt: releasedAt ?? now,
        })
      }
      if (isProductCreation) {
        // Create product flow
        createProduct({
          variables: createProductVariables,
          onCompleted: (data) => {
            actions.setSubmitting(false)
            history.push(`/inApp/products/${data.createProduct._id}`)
          },
        })
      } else {
        // TODO: CAS-4088
        // In case of propertyDefinitionType is TRANSLATION, we should keep the propertyValue (which is an ObjectId) and send it to api.
        // Right now in the api, we reuse the existing Ids for propertyDefinitionType values.
        updateProduct({
          variables: {
            args: {
              _id: product._id,
              ...createProductVariables.args,
            },
          },
          onCompleted: () => {
            actions.setSubmitting(false)
            history.push(`/inApp/products/${product._id}`)
          },
        })
      }
    },
  })

  const [selectedPropertyDefinitionId, setSelectedPropertyDefinitionId] =
    useState<string | null>(null)

  const { itemCategories } = useGetItemCategories(includeHidedCategories)

  const { values, setFieldValue: _setFieldValue, isValid } = formik

  // Formik does not strictly type `setFieldValue` (https://github.com/jaredpalmer/formik/issues/1388)
  // so we do it.
  const setFieldValue = useCallback(
    <T extends keyof ProductFormData>(
      field: T,
      value: ProductFormData[T],
      shouldValidate?: boolean,
    ) => {
      return _setFieldValue(field, value, shouldValidate)
    },
    [_setFieldValue],
  )

  const { productPropertyDefinitions } = useGetProductPropertyDefinitions({
    variables: {
      opts: {
        filter: {
          categoryId: values.categoryId,
        },
      },
    },
    skip: !values.categoryId,
  })

  useEffect(() => {
    if (
      !product ||
      (product?.categoryId &&
        values.categoryId &&
        product.categoryId !== values.categoryId)
    ) {
      setFieldValue(
        'availableProperties',
        productPropertyDefinitions?.map((d) => ({
          propertyDefinitionId: d._id,
          propertyDefinitionType: d.type,
          propertyDefinition: d,
          values: d.type === EProductPropertyType.Boolean ? undefined : [],
        })) ?? [],
      )
    }
  }, [setFieldValue, product, values.categoryId, productPropertyDefinitions])

  const hasEnabledVariants = values.variants.some((v) => v.enabled)

  const [releasedAt, setReleasedAt] = useState(values.variants[0]?.releasedAt)

  useEffect(() => {
    if (!hasEnabledVariants) {
      setFieldValue('isPublished', false)
    }
  }, [hasEnabledVariants, setFieldValue])

  useEffect(() => {
    if (values.categoryId && itemCategories.length) {
      setFieldValue(
        'manufacturer',
        itemCategories.find((category) => category._id === values.categoryId)
          ?.name,
      )
    }
  }, [values.categoryId, setFieldValue, itemCategories])

  useEffect(() => {
    setReleasedAt(values.variants[0]?.releasedAt || new Date())
  }, [values.variants])

  const getPropertyType = useCallback(
    (propertyDefinition: ProductPropertyDefinition) => {
      switch (propertyDefinition.type) {
        case EProductPropertyType.Translation: {
          return t('translated_text')
        }

        case EProductPropertyType.Number: {
          switch (propertyDefinition.valueFormatting?.suffix) {
            case EProductProperyDefinitionSuffix.Gb: {
              return `${t('product_property_definition.storage')} (${EProductProperyDefinitionSuffix.Gb})`
            }

            case EProductProperyDefinitionSuffix.Cm: {
              return `${t('product_property_definition.size')} (${EProductProperyDefinitionSuffix.Cm})`
            }

            case EProductProperyDefinitionSuffix.Inch: {
              return `${t('product_property_definition.size')} (${EProductProperyDefinitionSuffix.Inch})`
            }

            case EProductProperyDefinitionSuffix.G: {
              return `${t('weight')} (${EProductProperyDefinitionSuffix.G})`
            }

            case EProductProperyDefinitionSuffix.Ounce: {
              return `${t('weight')} (${EProductProperyDefinitionSuffix.Ounce})`
            }

            default: {
              return null
            }
          }
        }

        case EProductPropertyType.Boolean: {
          return t('boolean')
        }

        default: {
          return null
        }
      }
    },
    [t],
  )

  const otherPropertiesOptions = useMemo(() => {
    return (
      productPropertyDefinitions
        ?.filter(
          (d) =>
            !values.availableProperties
              .map((p) => p.propertyDefinitionId)
              .includes(d._id),
        )
        .map((d) => {
          return {
            label: d.key,
            value: d._id,
          }
        }) ?? []
    )
  }, [productPropertyDefinitions, values?.availableProperties])

  const currentCategories = itemCategories.filter(
    (category) => category._id === values.categoryId,
  )
  const currentPropertyDefinitions = values.availableProperties.map(
    (p) => p.propertyDefinition,
  )

  const allAvailablePropertiesValuesValid = useMemo(
    () =>
      values.availableProperties && values.availableProperties.length > 0
        ? values.availableProperties.every((property) => {
            if (
              property.propertyDefinitionType === EProductPropertyType.Boolean
            ) {
              return true
            }

            const validTranslationValues =
              property.propertyDefinitionType ===
                EProductPropertyType.Translation &&
              property.values.every(
                (p) =>
                  isNotEmptyPropertyValue(
                    property.propertyDefinition.type,
                    p.value,
                  ) && !p.error,
              )

            const validNumberValues =
              property.propertyDefinitionType === EProductPropertyType.Number &&
              property.values.every(
                (p) =>
                  isNotEmptyPropertyValue(
                    property.propertyDefinition.type,
                    p.value,
                  ) && !p.error,
              )

            return (
              !!property.values.length &&
              (validTranslationValues || validNumberValues)
            )
          })
        : true,
    [values.availableProperties],
  )

  const allVariantsValid = useMemo(
    () =>
      values.variants.every(
        (variant) =>
          variant.propertiesValues.every(
            (p) =>
              isNotEmptyPropertyValue(p.propertyDefinition.type, p.value) &&
              !p.error,
          ) && Boolean(variant.releasedAt),
      ),
    [values.variants],
  )

  const { lookVariants } = useGetLookVariants(values.variants)

  const allVariantsHaveCommittedImages = useMemo(
    () =>
      values.variants.every(
        (variant) => variant.imageId || variant.ephemeralImageId,
      ),
    [values.variants],
  )

  return (
    <div>
      <FormikProvider value={formik}>
        <Form onSubmit={formik.handleSubmit}>
          <Box style={{ display: 'flex', justifyContent: 'flex-start' }}>
            <Typography
              variant="h5"
              gutterBottom
              style={{ marginTop: 10, marginRight: 10 }}
            >
              <BackIcon>
                <Link to={id ? `/inApp/products/${id}` : `/inApp/products`}>
                  <ArrowBackIos />
                </Link>
              </BackIcon>
            </Typography>
            <Typography style={{ fontSize: 32, minWidth: 200 }}>
              {values.name}
            </Typography>
            <PublishButton
              disabled={!hasEnabledVariants}
              isPublished={values.isPublished}
              variant="outlined"
              onClick={() => {
                setOpenPublishConfirmPopup(true)
              }}
            >
              {values.isPublished ? t('published') : t('unpublished')}
            </PublishButton>
          </Box>
          <Box style={{ marginTop: 10 }}>
            <Typography>
              <CategorySelectorWrapper>
                <CategorySelector
                  label={<LabelWrapper>{t('category')}</LabelWrapper>}
                  categories={itemCategories}
                  onChange={(categoryId: string | string[]) => {
                    invariant(
                      !Array.isArray(categoryId),
                      'Expected a single category id, received an array',
                    )
                    setFieldValue('categoryId', categoryId)
                  }}
                  currentCategories={currentCategories}
                  disabled={!isProductCreation}
                />
              </CategorySelectorWrapper>
            </Typography>
          </Box>
          <FormikInput
            name="manufacturer"
            className="flex flex-col md:flex-row items-start md:items-center mb-2"
            labelClassName="text-sm font-semibold w-40"
            inputContainerClassName="w-80"
            label={t('manufacturer')}
          />
          <FormikInput
            name="name"
            className="flex flex-col md:flex-row items-start md:items-center mb-2"
            labelClassName="text-sm font-semibold w-40"
            inputContainerClassName="w-80"
            label={t('product')}
          />
          {!product?.isCrawled && (
            <DatePicker
              dateFormat="dd.mm.yy"
              value={releasedAt}
              onChange={(event) => {
                if (
                  typeof event.value !== 'object' ||
                  !(event.value instanceof Date)
                ) {
                  throw new Error(
                    'Unexpected date value received in date picker',
                  )
                }
                const newDate = event.value
                setReleasedAt(newDate)

                if (values.variants?.length > 0) {
                  setFieldValue(
                    'variants',
                    values.variants.map((variant) => {
                      return {
                        ...variant,
                        releasedAt: newDate,
                      }
                    }),
                  )
                }
              }}
              label={t('release_date')}
              className="flex flex-col md:flex-row items-start md:items-center mr-2"
              labelClassName="text-sm font-semibold w-40"
            />
          )}
          <Box style={{ marginTop: 20 }}>
            <Box style={{ display: 'flex', justifyContent: 'flex-start' }}>
              <Typography variant="h5" style={{ width: 200 }}>
                {t('properties')}
              </Typography>
            </Box>
            {isProductCreation && (
              <>
                <Box style={{ marginTop: 20 }}>
                  <Typography variant="h6">
                    {t('current_properties')}
                  </Typography>
                  <Box>
                    {currentPropertyDefinitions?.map((d) => (
                      <Box
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-start',
                        }}
                      >
                        <Typography>
                          {d.key} ({t('type')}: {getPropertyType(d)})
                        </Typography>
                        <Box>
                          <ReactSVG
                            className={`u-pl-15`}
                            src={DeleteIcon}
                            style={{ marginTop: 5, cursor: 'pointer' }}
                            onClick={() => {
                              const availableProperties =
                                values.availableProperties.filter(
                                  (p) => p.propertyDefinitionId !== d._id,
                                )
                              setFieldValue(
                                'availableProperties',
                                availableProperties,
                              )
                            }}
                          />
                        </Box>
                      </Box>
                    ))}
                  </Box>
                </Box>
                {otherPropertiesOptions.length > 0 && (
                  <Box style={{ marginTop: 20 }}>
                    <Typography variant="h6">
                      {t('add_more_properties')}
                    </Typography>
                    <Box
                      style={{ display: 'flex', justifyContent: 'flex-start' }}
                    >
                      <SelectOptionInput
                        style={{ marginTop: 10 }}
                        value={selectedPropertyDefinitionId}
                        onChange={(value) => {
                          setSelectedPropertyDefinitionId(value)
                        }}
                        menuItems={otherPropertiesOptions}
                      />
                      <AddPropertyButton
                        disabled={!selectedPropertyDefinitionId}
                        onClick={() => {
                          const propertyDefinition =
                            productPropertyDefinitions.find(
                              (d) => d._id === selectedPropertyDefinitionId,
                            )

                          if (
                            !selectedPropertyDefinitionId ||
                            !propertyDefinition
                          )
                            return
                          setFieldValue('availableProperties', [
                            ...(values.availableProperties ?? []),
                            {
                              propertyDefinitionId:
                                selectedPropertyDefinitionId,
                              propertyDefinitionType: propertyDefinition.type,
                              propertyDefinition: propertyDefinition,
                              values:
                                propertyDefinition.type ===
                                EProductPropertyType.Boolean
                                  ? undefined
                                  : [],
                            },
                          ])

                          setSelectedPropertyDefinitionId(null)
                        }}
                      >
                        {t('add_property')}
                      </AddPropertyButton>
                    </Box>
                  </Box>
                )}
              </>
            )}
            <Box style={{ marginTop: 30 }}>
              {values.availableProperties.map((property) => (
                <ProductEditAvailableProperty
                  property={property}
                  availableProperties={values.availableProperties}
                  setFieldValue={setFieldValue}
                  variants={values.variants}
                />
              ))}
            </Box>

            <ProductKeywords />

            <ProductVariants
              variants={values.variants}
              availableProperties={values.availableProperties}
              setFieldValue={setFieldValue}
            />
            {allVariantsValid ? (
              <ProductImages
                variants={values.variants ?? []}
                setFieldValue={setFieldValue}
                productImage={values.image}
                overviewImage={values.overviewImage}
                imageMode={values.imageMode}
              />
            ) : (
              <Box style={{ marginTop: 20 }}>
                <Typography color="error">
                  {t('product_variants_are_invalid')}
                </Typography>
              </Box>
            )}

            <Box
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                marginTop: 30,
              }}
            >
              <Link
                to={
                  product?._id
                    ? `/inApp/products/${product._id}`
                    : '/inApp/products'
                }
              >
                <Button
                  style={{ borderRadius: 100 }}
                  variant="outlined"
                  color="primary"
                  size="large"
                >
                  {t('cancel')}
                </Button>
              </Link>
              <Button
                disabled={
                  !isValid ||
                  !allAvailablePropertiesValuesValid ||
                  !allVariantsValid ||
                  (values.imageMode === EProductImageMode.ImagePerVariant &&
                    lookVariants.length > 0 &&
                    !allVariantsHaveCommittedImages)
                }
                style={{ borderRadius: 100, marginLeft: 10 }}
                variant="contained"
                color="primary"
                size="large"
                type="submit"
              >
                {t('save')}
              </Button>
            </Box>
          </Box>
          <ProductPublishConfirmPopup
            text={
              values.isPublished
                ? t('product_unpublish_confirm_popup')
                : t('product_publish_confirm_popup')
            }
            open={!!openPublishConfirmPopup}
            onClose={() => setOpenPublishConfirmPopup(false)}
            onSave={() => {
              setFieldValue('isPublished', !values.isPublished)
              setOpenPublishConfirmPopup(false)
            }}
          />
        </Form>
      </FormikProvider>
    </div>
  )
}

type PropertyValueWithContext = PropertyValue & {
  status: 'published' | 'draft'
}

function generateMissingVariants(
  availableProperties: ProductAvailableProperty[],
  variants: ProductVariant[],
  releasedAt: Date,
): ProductVariantArgs[] {
  if (
    availableProperties.length === 0 ||
    availableProperties.every((property) => {
      if (property.propertyDefinitionType === EProductPropertyType.Boolean) {
        return true
      }
      return property.values.every(
        (v: ProductNumberPropertyValue | ProductTranslationPropertyValue) =>
          v.status === 'published',
      )
    })
  ) {
    return []
  }
  const propertyValuesWithContext: PropertyValueWithContext[][] = []

  for (const availableProperty of availableProperties) {
    if (
      availableProperty.propertyDefinitionType === EProductPropertyType.Boolean
    ) {
      propertyValuesWithContext.push(
        [true, false].map(
          (availablePropertyValue): PropertyValueWithContext => ({
            propertyDefinition: availableProperty.propertyDefinition,
            propertyDefinitionType: EProductPropertyType.Boolean,
            status: 'published',
            value: availablePropertyValue,
          }),
        ),
      )
      continue
    } else if (
      availableProperty.propertyDefinitionType === EProductPropertyType.Number
    ) {
      propertyValuesWithContext.push(
        availableProperty.values.map(
          (value): PropertyValueWithContext => ({
            propertyDefinition: availableProperty.propertyDefinition,
            propertyDefinitionType: EProductPropertyType.Number,
            ...value,
          }),
        ),
      )
    } else if (
      availableProperty.propertyDefinitionType ===
      EProductPropertyType.Translation
    ) {
      propertyValuesWithContext.push(
        availableProperty.values.map(
          (value): PropertyValueWithContext => ({
            propertyDefinition: availableProperty.propertyDefinition,
            propertyDefinitionType: EProductPropertyType.Translation,
            ...value,
          }),
        ),
      )
    }
  }
  const missingValuesCombinations = getCombinations(
    propertyValuesWithContext,
  ).filter((valuesCombinations) =>
    valuesCombinations.some((value) => value.status === 'draft'),
  )
  if (!missingValuesCombinations.length) {
    return []
  }
  return missingValuesCombinations.map(
    (propertiesValues): ProductVariantArgs => {
      const existingLookVariant = variants.find(
        (variant) =>
          JSON.stringify(
            variant.propertiesValues
              .filter((pv) => pv.propertyDefinition.impactsLook)
              .map((v) => v.value),
          ) ===
          JSON.stringify(
            propertiesValues
              .filter((pv) => pv.propertyDefinition.impactsLook)
              .map((v) => v.value),
          ),
      )
      return {
        enabled: false,
        imageId: existingLookVariant?.imageId,
        releasedAt: releasedAt,
        propertiesValues: propertiesValues.map((v) =>
          createProductVariantPropertyValue(v),
        ),
      }
    },
  )
}

function createProductVariantPropertyValue(
  propertyValue: Pick<PropertyValue, 'propertyDefinition' | 'value'>,
): ProductVariantValueArgs {
  const value: ProductVariantValueOptionArgs = {}
  if (
    propertyValue.propertyDefinition.type === EProductPropertyType.Translation
  ) {
    value.textOption = {
      translation: [
        {
          languageCode: ELanguageCode.En,
          text:
            (propertyValue.value as ProductAvailablePropertyTextOptions).en ??
            '',
        },
        {
          languageCode: ELanguageCode.De,
          text:
            (propertyValue.value as ProductAvailablePropertyTextOptions).de ??
            '',
        },
      ],
    }
  } else if (
    propertyValue.propertyDefinition.type === EProductPropertyType.Number
  ) {
    value.numberOption = propertyValue.value as number
  } else if (
    propertyValue.propertyDefinition.type === EProductPropertyType.Boolean
  ) {
    value.booleanOption = propertyValue.value as boolean
  }
  return {
    propertyDefinitionId: propertyValue.propertyDefinition._id,
    value,
  }
}

const BackIcon = styled.span`
  a {
    display: inline-block;
    color: #212121;
    height: 24px;
    width: 24px;
  }
`

export const LabelWrapper = styled(Typography)`
  min-width: 130px;
  font-weight: 500;
`

const CategorySelectorWrapper = styled.div`
  .MuiInputBase-root.MuiInput-root {
    width: 200px;
  }
`

const PublishButton = styled(Button)<{
  isPublished?: boolean
}>`
  &&& {
    height: 35px;
    margin-left: 20px;
    margin-top: 10px;
    color: ${(props) => (props.isPublished ? '#2ECC71' : '#C0392B')};
    border-color: ${(props) => (props.isPublished ? '#2ECC71' : '#C0392B')};
  }
`

const AddPropertyButton = styled(Button)`
  &&& {
    background: linear-gradient(
        0deg,
        rgba(103, 80, 164, 0.05),
        rgba(103, 80, 164, 0.05)
      ),
      #fffbfe;
    height: 19px;
    min-width: 118px;
    box-shadow:
      0px 1px 2px rgba(0, 0, 0, 0.3),
      0px 1px 3px 1px rgba(0, 0, 0, 0.15);
    border-radius: 100px;
    font-size: 12px !important;
    color: #6750a4;
    margin-top: 20px;
    margin-left: 20px;
  }
`
