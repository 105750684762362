import { Typography } from '@material-ui/core'
import { useField } from 'formik'
import { Button } from 'primereact/button'
import { Chip } from 'primereact/chip'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Input from '@/redesign/components/Input/Input'

export const ProductKeywords = () => {
  const { t } = useTranslation()
  const [value, setValue] = useState('')

  const [field, meta, helpers] = useField('keywords')

  const keywords: string[] = field.value

  return (
    <>
      <Typography variant="h5">Keywords</Typography>
      <div className="flex justify-inbetween gap-2 items-center">
        <Input
          className="w-34"
          value={value}
          onChange={(event) => setValue(event.target.value)}
        />
        <Button
          className="h-10"
          icon="pi pi-plus"
          iconPos="right"
          label={t('add')}
          disabled={value === '' || keywords.includes(value)}
          onClick={async (event) => {
            event.preventDefault()
            helpers.setValue([...keywords, value])
            setValue('')
          }}
        />
      </div>
      <div className="flex flex-wrap gap-2 mt-2">
        {keywords?.map((keyword) => (
          <Chip
            key={keyword}
            label={keyword}
            onRemove={() => {
              helpers.setValue(
                keywords.filter((newKeyword) => newKeyword != keyword),
              )
            }}
            removable
          />
        ))}
      </div>
      {meta.error && <Typography color="error">{meta.error}</Typography>}
    </>
  )
}
