import {
  ProductAvailableProperty,
  ProductFormData,
  ProductNumberPropertyValue,
  ProductTranslationPropertyValue,
  ProductVariant,
} from '../interfaces'
import { uniqueId } from 'lodash'
import { useMemo } from 'react'
import {
  AvailablePropertyTextOption,
  ELanguageCode,
  EProductImageMode,
  EProductPropertyType,
  Product,
} from '@/schemaTypes'

const getTranslationObject = (value: AvailablePropertyTextOption) => ({
  en: value.translation.allTranslations.find(
    (a) => a.languageCode === ELanguageCode.En,
  )?.text,
  de: value.translation.allTranslations.find(
    (a) => a.languageCode === ELanguageCode.De,
  )?.text,
})

export const useGetProductFormData = (
  product: Product | null | undefined,
): ProductFormData => {
  const initialAvailableProperties =
    useMemo((): Array<ProductAvailableProperty> => {
      return (
        product?.availableProperties.map((p) => {
          const availableProperty: Pick<
            ProductAvailableProperty,
            'propertyDefinition' | 'propertyDefinitionId'
          > = {
            propertyDefinitionId: p.propertyDefinitionId,
            propertyDefinition: p.propertyDefinition,
          }
          if (p.__typename === 'ProductAvailableTextProperty') {
            return {
              ...availableProperty,
              propertyDefinitionType: EProductPropertyType.Translation,
              values: p.values.map((value): ProductTranslationPropertyValue => {
                return {
                  value: getTranslationObject(value),
                  id: value.id,
                  valueId: uniqueId(),
                  status: 'published',
                }
              }),
            }
          }

          if (p.__typename === 'ProductAvailableNumberProperty') {
            return {
              ...availableProperty,
              propertyDefinitionType: EProductPropertyType.Number,
              values: p.values.map((value): ProductNumberPropertyValue => {
                return {
                  value: value.numberValue,
                  valueId: uniqueId(),
                  status: 'published',
                }
              }),
            }
          }

          if (p.__typename === 'ProductAvailableBooleanProperty') {
            return {
              ...availableProperty,
              propertyDefinitionType: EProductPropertyType.Boolean,
            }
          }

          throw new Error('Invalid property value type')
        }) ?? []
      )
    }, [product?.availableProperties])

  const initialVariants = useMemo((): Array<ProductVariant> => {
    return (
      product?.variants.map((v) => {
        return {
          id: v.id,
          imageId: v.image?.id,
          imageUrl: v.image?.url,
          enabled: v.enabled,
          releasedAt: v.releasedAt,
          propertiesValues: v.propertiesValues.map((propertyValue) => {
            const propertyValues = initialAvailableProperties.find(
              (p) =>
                p.propertyDefinitionId === propertyValue.propertyDefinition._id,
            )

            if (
              propertyValue.__typename ===
                'ProductVariantPropertyNumberValue' &&
              propertyValues?.propertyDefinitionType ===
                EProductPropertyType.Number
            ) {
              const valueId =
                propertyValues.values.find(
                  (v) => v.value === propertyValue.value.numberValue,
                )?.valueId ?? null
              return {
                value: propertyValue.value.numberValue,
                propertyDefinition: propertyValue.propertyDefinition,
                propertyDefinitionType: EProductPropertyType.Number,
                valueId,
              }
            }

            if (
              propertyValue.__typename === 'ProductVariantPropertyBooleanValue'
            ) {
              return {
                value: propertyValue.value.booleanValue,
                propertyDefinition: propertyValue.propertyDefinition,
                propertyDefinitionType: EProductPropertyType.Boolean,
              }
            }

            if (
              propertyValue.__typename === 'ProductVariantPropertyTextValue' &&
              propertyValues?.propertyDefinitionType ===
                EProductPropertyType.Translation
            ) {
              const textValue = getTranslationObject(propertyValue.value)
              const valueId =
                propertyValues.values.find(
                  (v) => JSON.stringify(v.value) === JSON.stringify(textValue),
                )?.valueId ?? null
              return {
                value: {
                  en: propertyValue.value.translation.allTranslations.find(
                    (a) => a.languageCode === ELanguageCode.En,
                  )?.text,
                  de: propertyValue.value.translation.allTranslations.find(
                    (a) => a.languageCode === ELanguageCode.De,
                  )?.text,
                },
                valueId,
                propertyDefinition: propertyValue.propertyDefinition,
                propertyDefinitionType: EProductPropertyType.Translation,
              }
            }

            throw new Error('Invalid property value type')
          }),
        }
      }) ?? []
    )
  }, [product?.variants, initialAvailableProperties])

  return {
    name: product?.name,
    categoryId: product?.categoryId,
    manufacturer: product?.manufacturer,
    imageMode: product?.imageMode ?? EProductImageMode.ImagePerVariant,
    isPublished: product?.isPublished ?? false,
    image: product?.image,
    overviewImage: product?.overviewImage,
    keywords: product?.keywords || [],
    availableProperties: initialAvailableProperties,
    variants: initialVariants,
  }
}
